export default {
  setData: (state, data) => {
    state.data = data.drivers;
    state.total = {
      totalItems: data.total,
      totalPages: data.last_page,
    };
  },

  setCaptains: (state, data) => {
    state.captains = data.users;
  },

  setWallets: (state, data) => {
    state.wallets = data.wallets
  },

  setCurrencies: (state, data) => {
    state.currencies = data.currencies
  },

  setVendors: (state, data) => {
    console.log(data.users)
    state.vendors = data.users
  },

  setAccounts: (state, data) => {
    console.log(data.accounts)
    state.accounts = data.accounts
  },

  setTransactions: (state, data) => {
    state.transactions = data.data
  },

  setPenalties: (state, data) => {
    state.penalties = data.penalties
  },

  setBonuses: (state, data) => {
    state.bonuses = data.bonuses
  },

  setApplyFor: (state, data) => {
    state.applyFor = data;
  },


};
