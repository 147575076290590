<template>
  <div>
    <!-- Column Toggle Menu -->
    <!-- Horizontal Scrollable Checkbox Row -->
<!--    <div class="d-flex flex-row text-nowrap overflow-auto gap-4 border rounded p-3 bg-light">-->
<!--      <b-form-checkbox-->
<!--        v-for="field in fields"-->
<!--        :key="field.key"-->
<!--        class="form-check-inline mx-3"-->
<!--        v-model="visibleColumns"-->
<!--        :value="field.key"-->
<!--        :unchecked-value="null"-->
<!--      >-->
<!--        {{ field.label }}-->
<!--      </b-form-checkbox>-->
<!--    </div>-->

    <div class="w-table d-flex flex-column">
      <b-table
        noDataTitle="لا توجد بيانات"
        noDataSubtitle="لم يتم العثور على بيانات"
        :foot-clone="true"
        no-footer-sorting
        responsive
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="items"
        :fields="computedFields"
        :custimized-items="[
        { name: 'number' },
        { name: 'driver_name' },
        { name: 'total_orders' },
        { name: 'total_order_price' },
        { name: 'total_delivery_price' },
        { name: 'total_fee' },
        { name: 'total_invoice' },
        { name: 'total_cash_order_price' },
        { name: 'total_postpaid_order_price' },
        { name: 'total_discount_price' },
        { name: 'total_customer_paid_price' },
        { name: 'total_delivery_discount_provider' },
        { name: 'delivery_price_after_discount' },
        { name: 'company_compensation' },
        // { name: 'company_percentage' },
        { name: 'company_percentage_value' },
        { name: 'holidays_allowance' },
        { name: 'exceptional_compensation' },
        { name: 'net_amount_due' },
        { name: 'net_amount_for_company_before_compensations' },
        { name: 'net_amount_for_company_after_compensations' },
        { name: 'net_amounts_to_be_supplied' },
        { name: 'amounts_received' },
        // { name: 'insurance_amount' },
        { name: 'balance' },
        // { name: 'cash' },
        // { name: 'advance' },
        // { name: 'wallet' },
      ]"
      >
        <template slot="number" slot-scope="{ data }">{{ data.item.driver_id }}</template>
        <template slot="driver_name" slot-scope="{ data }">
          <div class="order-table_name">
            <name-tile :name="data.item.driver_name" hide-image />
          </div>
        </template>
        <template slot="total_orders" slot-scope="{ data }">{{ data.item.total_orders }}</template>
        <template slot="total_order_price" slot-scope="{ data }"><check-value :value="data.item.total_order_price" /></template>
        <template slot="total_delivery_price" slot-scope="{ data }"><check-value :value="data.item.total_delivery_price" /></template>
        <template slot="total_fee" slot-scope="{ data }"><check-value :value="data.item.total_fee" /></template>
        <template slot="total_invoice" slot-scope="{ data }"><check-value :value="data.item.total_invoice" /></template>
        <template slot="total_cash_order_price" slot-scope="{ data }"><check-value :value="data.item.total_cash_order_price" /></template>
        <template slot="total_postpaid_order_price" slot-scope="{ data }"><check-value :value="data.item.total_postpaid_order_price" /></template>
        <template slot="total_discount_price" slot-scope="{ data }"><check-value :value="data.item.total_discount_price" /></template>
        <template slot="total_delivery_discount_price" slot-scope="{ data }"><check-value :value="data.item.total_delivery_discount_price" /></template>
        <template slot="total_customer_paid_price" slot-scope="{ data }"><check-value :value="data.item.total_customer_paid_price" /></template>
        <template slot="total_delivery_discount_provider" slot-scope="{ data }"><check-value :value="data.item.total_delivery_discount_provider" /></template>
        <template slot="delivery_price_after_discount" slot-scope="{ data }"><check-value :value="data.item.delivery_price_after_discount" /></template>
        <template slot="company_compensation" slot-scope="{ data }"><check-value :value="data.item.company_compensation" /></template>
<!--        <template slot="company_percentage" slot-scope="{ data }">{{ data.item.company_percentage }}</template>-->
        <template #cell(company_percentage_value)="data"><check-value :value="data.item.company_percentage_value" /></template>
        <template slot="bonuses" slot-scope="{ data }"><check-value :value="data.item.bonuses" /></template>
        <template slot="penalties" slot-scope="{ data }"><check-value :value="data.item.penalties" /></template>
        <template slot="holidays_allowance" slot-scope="{ data }"><check-value :value="data.item.holidays_allowance" /></template>
        <template slot="exceptional_compensation" slot-scope="{ data }"><check-value :value="data.item.exceptional_compensation" /></template>
<!--        <template #cell(net_amount_due)="data"><check-value :value="(data.item.delivery_price_after_discount - data.item.delivery_price_after_discount * (data.item.company_percentage / 100))" /></template>-->
        <template slot="net_amount_due" slot-scope="{ data }"><check-value :value="data.item.net_amount_due" /></template>
        <template slot="net_amount_for_company_before_compensations" slot-scope="{ data }"><check-value :value="data.item.net_amount_for_company_before_compensations" /></template>
        <template slot="net_amount_for_company_after_compensations" slot-scope="{ data }"><check-value :value="data.item.net_amount_for_company_after_compensations" /></template>
        <template slot="net_amounts_to_be_supplied" slot-scope="{ data }"><check-value :value="data.item.net_amounts_to_be_supplied" /></template>
        <template slot="amounts_received" slot-scope="{ data }"><check-value :value="data.item.amounts_received" /></template>
//        <template slot="insurance_amount" slot-scope="{ data }"><check-value :value="data.item.insurance_amount" /></template>
        <template slot="balance" slot-scope="{ data }"><check-value :value="data.item.balance" /></template>
        <!--      <template slot="cash" slot-scope="{ data }"><check-value :value="data.item.cash" /></template>-->
        <!--      <template slot="advance" slot-scope="{ data }"><check-value :value="data.item.advance" /></template>-->
        <!--      <template slot="wallet" slot-scope="{ data }"><check-value :value="data.item.wallet" /></template>-->


        <template #foot(driver_name)>{{ $t('table.total') }}</template>
        <template #foot(total_orders)>{{ totalOrderCount }}</template>
        <template #foot(total_order_price)>{{ totalOrderValueTotal }}</template>
        <template #foot(total_delivery_price)>{{ totalDeliveryTotal }}</template>
        <template #foot(total_fee)>{{ totalServiceFeeTotal }}</template>
        <template #foot(total_invoice)>{{ totalInvoiceTotal }}</template>
        <template #foot(total_cash_order_price)>{{ totalCashOrdersTotal }}</template>
        <template #foot(total_postpaid_order_price)>{{ totalPostpaidOrdersTotal }}</template>
        <template #foot(total_discount_price)>{{ totalFromSupplier }}</template>
        <template #foot(total_delivery_discount_price)>{{ totalDeliveryDiscountPrice }}</template>
        <template #foot(total_customer_paid_price)>{{ totalDeliveryPaidByClient }}</template>
        <template #foot(total_delivery_discount_provider)>{{ totalDeliveryResponsibility }}</template>
        <template #foot(delivery_price_after_discount)>{{ totalDeliveryPriceAfterDiscount }}</template>
        <template #foot(company_compensation)>{{ totalCompanyCompensation }}</template>
<!--        <template #foot(company_percentage)>{{ totalCompanyPercentage }}</template>-->
        <template #foot(company_percentage_value)>{{ totalCompanyPercentageValue }}</template>
        <template #foot(bonuses)>{{ totalCompanyPercentageValue }}</template>
        <template #foot(penalties)>{{ totalCompanyPercentageValue }}</template>
        <template #foot(holidays_allowance)>{{ totalHolidaysAllowance }}</template>
        <template #foot(exceptional_compensation)>{{ totalExceptionalCompensation }}</template>
        <template #foot(net_amount_due)>{{ totalNetAmountDue }}</template>
        <template #foot(net_amount_for_company_before_compensations)>{{ totalNetCompanyBeforeCompensation }}</template>
        <template #foot(net_amount_for_company_after_compensations)>{{ totalNetCompanyAfterCompensation }}</template>
        <template #foot(net_amounts_to_be_supplied)>{{ totalCompanyFinal }}</template>
        <!--      <template #foot(cash)>{{ totalCash }}</template>-->
        <!--      <template #foot(advance)>{{ totalAdvance }}</template>-->
        <!--      <template #foot(wallet)>{{ totalWallet }}</template>-->
      </b-table>
    </div>

  </div>
</template>

<script>
import NameTile from "@/components/nameTile.table";
import CheckPrice from "@/components/checkPrice";
import WameedDropdown from '@/components/wameed/WameedDropdown.vue';
import { WameedTables as WTables } from 'wameed-ui/dist/wameed-ui.esm';
import CheckValue from '@/components/checkValue.vue';

export default {
  props: {
    items: Array,
    visibleColumns: Array,
    fields: Array,  // Receiving the fields array from the parent component
  },
  components: {
    CheckValue,
    NameTile,
    CheckPrice,
    WameedDropdown,
    WTables,
  },
  data() {
    return {
      sortBy: 'total_orders',
      sortDesc: true,
    };
  },
  computed: {
    computedFields() {
      // Filter fields based on visibility
      return this.fields.filter(field => this.visibleColumns.includes(field.key));
    },
    // Totals for each column
    totalOrderCount() {
      return this.items.reduce((sum, captain) => sum + captain.total_orders, 0);
    },
    totalOrderValueTotal() {
      return this.items.reduce((sum, captain) => sum + captain.total_order_price, 0);
    },
    totalDeliveryTotal() {
      return this.items.reduce((sum, captain) => sum + captain.total_delivery_price, 0);
    },
    totalServiceFeeTotal() {
      return this.items.reduce((sum, captain) => sum + captain.total_fee, 0);
    },
    totalInvoiceTotal() {
      return this.items.reduce((sum, captain) => sum + captain.total_invoice, 0);
    },
    totalCashOrdersTotal() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_cash_order_price, 0)*/0;
    },
    totalPostpaidOrdersTotal() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_postpaid_order_price, 0)*/0;
    },
    totalFromSupplier() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_discount_price, 0)*/0;
    },
    totalDeliveryPaidByClient() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_customer_paid_price, 0)*/0;
    },
    totalDeliveryResponsibility() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_delivery_discount_provider, 0)*/0;
    },
    totalDeliveryPriceAfterDiscount() {
      return /*this.items.reduce((sum, captain) => sum + captain.delivery_price_after_discount, 0)*/0;
    },
    totalDeliveryDiscountPrice() {
      return /*this.items.reduce((sum, captain) => sum + captain.total_delivery_discount_price, 0)*/0;
    },
    totalCompanyCompensation() {
      return /*this.items.reduce((sum, captain) => sum + captain.company_compensation, 0)*/0;
    },
    totalCompanyPercentage() {
      return /*`${(this.items.reduce((sum, captain) => sum + parseFloat(captain.company_percentage || 0), 0) / this.captains.length).toFixed(2)}%`*/0;
    },
    totalCompanyPercentageValue() {
      return /*this.items.reduce((sum, captain) => sum + captain.company_percentage_value, 0)*/0;
    },
    totalHolidaysAllowance() {
      return /*this.items.reduce((sum, captain) => sum + captain.holidays_allowance, 0)*/0;
    },
    totalExceptionalCompensation() {
      return /*this.items.reduce((sum, captain) => sum + captain.exceptional_compensation, 0)*/0;
    },
    totalNetAmountDue() {
      return /*this.items.reduce((sum, captain) => sum + captain.net_amount_due, 0)*/0;
    },
    totalNetCompanyBeforeCompensation() {
      return /*this.items.reduce((sum, captain) => sum + captain.net_amount_for_company_before_compensations, 0)*/0;
    },
    totalNetCompanyAfterCompensation() {
      return /*this.items.reduce((sum, captain) => sum + captain.net_amount_for_company_after_compensations, 0)*/0;
    },
    totalCompanyFinal() {
      return /*this.items.reduce((sum, captain) => sum + captain.net_amounts_to_be_supplied, 0)*/0;
    },
    totalCash() {
      return /*this.items.reduce((sum, captain) => sum + captain.cash, 0)*/0;
    },
    totalAdvance() {
      return /*this.items.reduce((sum, captain) => sum + captain.advance, 0)*/0;
    },
    totalWallet() {
      return /*this.items.reduce((sum, captain) => sum + captain.wallet, 0)*/0;
    },
    totalHeldFromDrivers() {
      return /*this.items.reduce((sum, captain) => sum + captain.held_from_driver, 0)*/0;
    },
    totalBalanceOnDrivers() {
      return /*this.items.reduce((sum, captain) => sum + captain.balance_on_driver, 0)*/0;
    },
  },

  mounted() {
    // console.table(this.items.filter(item => item.driver_id === 3308))
  },
  // created() {
  //   // Initialize visibleColumns with all field keys
  //   this.visibleColumns = this.fields.map(field => field.key);
  // },
};
</script>

<style>
/* Style for scrollable dropdown */
.dropdown-scroll {
  max-height: 200px; /* Set your desired fixed height */
  overflow-y: auto; /* Add vertical scrolling */
}
</style>