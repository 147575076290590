<template>
  <div class="order-detail--card mb-5">
    <div class="order-detail-info">


      <div class="flex">
        <div class="tile-text">
          <div class="text">التاريخ</div>
          <div class="value">{{ formattedDate }}</div>
        </div>
        <div class="tile-text">
          <div class="text">اليوم</div>
          <div class="value">{{ dayNameArabic }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text">
          <div class="text">المدفوع نقداً</div>
          <div class="value">{{ formatNumber(totalOrderValueTotal) }}</div>
        </div>
        <div class="tile-text">
          <div class="text">إجمالي المبالغ المستلمة من العملاء</div>
          <div class="value">{{ formatNumber(totalDeliveryPaidByClient) }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text">
          <div class="text">المستحقة للمناديب</div>
          <div class="value">
            <check-price :price="totalNetAmountDue" />
          </div>
        </div>
        <div class="tile-text">
          <div class="text">المستحقة للشركة</div>
          <div class="value">
            <check-price :price="totalCompanyFinal" />
          </div>
        </div>
      </div>


    </div>

    <div class="h-divider"></div>
    <div class="d-flex justify-content-between align-items-center flex-wrap" style=" gap: 16px;">

<!--      <div class="order-detail-btns " v-if="showBtns">
        <wameed-btn
            v-if="('can_update_price' in detail) && detail.can_update_price"
            variant="main"
            size="sm"
            :title="addPriceBtnText"
            classes="text-book-18  rounded-12   px-4 main-btn"
            block
            @onClick="$emit('onAddPrices')"
        />
        <wameed-btn
            variant="gray"
            size="sm"
            :title="$t('orders.edit')"
            classes="text-book-18  rounded-12   px-4 secondary-btn"
            block
            @onClick="$emit('onEdit')"
        />
        <wameed-btn
            variant="success"
            size="sm"
            :title="$t('orders.accept')"
            classes="text-book-18  rounded-12   px-4 success-btn"
            block

            @onClick="$emit('onAccept')"
        />
        <wameed-btn
            variant="danger"
            size="sm"
            :title="$t('orders.cancel')"
            classes="text-book-18  rounded-12   px-4"
            block

            @onClick="$emit('onCancel')"
        />
      </div>-->

<!--      <wameed-btn
          variant="outline-main"
          size="sm"
          prependIcon="btn-arrow-left-icon"
          :title="$t('status.change')"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onChangeStatus')"
      />-->
    </div>
  </div>
</template>
<script>

import { now } from 'moment';
import CheckPrice from '@/components/checkPrice.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'infoSection',
  components: { CheckPrice },
  methods: {
    now,
    ...mapActions({
      loadDayReport: 'admin/financialReports/get'
    }),
    loadData() {
      this.loadDayReport({
        // type: this.$route.meta.type,
        ...this.date
      });
      console.log(this.data);
    },
  },
  props: {
    date: {
      // type: Date,
      default: new Date()
    },
    detail: {
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    addPriceBtnText: {
      type: String,
      default: ""
    },
  },
  computed: {
    ...mapGetters({
      getAllFinancialReports: 'admin/financialReports/getData',
      // getTotalFinancial: 'admin/financial/getTotal',
    }),
    // Totals for each column
    totalOrderCount() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_orders, 0);
    },
    totalOrderValueTotal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_order_price, 0);
    },
    totalDeliveryTotal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_delivery_price, 0);
    },
    totalServiceFeeTotal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_fee, 0);
    },
    totalInvoiceTotal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_invoice, 0);
    },
    totalCashOrdersTotal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_cash_order_price, 0);
    },
    totalFromSupplier() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_discount_price, 0)*/0;
    },
    totalDeliveryPaidByClient() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_customer_paid_price, 0);
    },
    totalDeliveryResponsibility() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.total_delivery_discount_provider, 0)*/0;
    },
    totalDeliveryPriceAfterDiscount() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.delivery_price_after_discount, 0)*/0;
    },
    totalCompanyCompensation() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.company_compensation, 0)*/0;
    },
    totalCompanyPercentage() {
      return /*`${(this.getAllFinancialReports.reduce((sum, captain) => sum + parseFloat(captain.company_percentage || 0), 0) / this.captains.length).toFixed(2)}%`*/0;
    },
    totalCompanyPercentageValue() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.company_percentage_value, 0)*/0;
    },
    totalHolidaysAllowance() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.holidays_allowance, 0)*/0;
    },
    totalExceptionalCompensation() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.exceptional_compensation, 0)*/0;
    },
    totalNetAmountDue() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.net_amount_due, 0);
    },
    totalNetCompanyBeforeCompensation() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.net_amount_for_company_before_compensations, 0)*/0;
    },
    totalNetCompanyAfterCompensation() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.net_amount_for_company_after_compensations, 0)*/0;
    },
    totalCompanyFinal() {
      return this.getAllFinancialReports.reduce((sum, captain) => sum + captain.net_amounts_to_be_supplied, 0);
    },
    totalCash() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.cash, 0)*/0;
    },
    totalAdvance() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.advance, 0)*/0;
    },
    totalWallet() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.wallet, 0)*/0;
    },
    totalHeldFromDrivers() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.held_from_driver, 0)*/0;
    },
    totalBalanceOnDrivers() {
      return /*this.getAllFinancialReports.reduce((sum, captain) => sum + captain.balance_on_driver, 0)*/0;
    },
    dayNameArabic() {
      return new Intl.DateTimeFormat('ar-EG', { weekday: 'long' }).format(new Date(this.date));
    },
    // Format date as yyyy-mm-dd
    formattedDate() {
      const year = (new Date(this.date)).getFullYear();
      const month = String((new Date(this.date)).getMonth() + 1).padStart(2, '0');
      const day = String((new Date(this.date)).getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  created() {
    console.log(this.getAllFinancialReports);
  }
}
</script>