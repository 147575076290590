<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('financial.title')"
    >
      <template v-slot:btns>
        <div class="outline-link" @click="exportVendorsToExcel">
          <o-export-icon/>
          {{ $t('common.export_excel') }}
        </div>
      </template>
    </page-header>

    <filter-header
        :content="filterContent"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderItems"
    />

    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1">

        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column ">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getAllFinancial"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getAllFinancial"
                :fields="fields"
                :class="'financial_table'"
                :custimized-items="[
                {name:'date'},
                {name:'time'},
                {name:'payment_method'},
                {name:'payment_type'},
                { name: 'amount' },
                { name: 'action' },

                {name:'order_price'},
                {name:'delivery_price'},

                {name:'provider_order_discount'},
                {name:'calc_provider_order_discount'},
                {name:'talqah_order_discount'},
                {name:'calc_talqah_order_discount'},
                {name:'calc_order_discount'},
                {name:'provider_delivery_discount'},
                {name:'calc_provider_delivery_discount'},
                {name:'talqah_delivery_discount'},
                {name:'calc_talqah_delivery_discount'},
                {name:'calc_delivery_discount'},
                {name:'vendor_talqah_commission_percent'},
                {name:'talqah_order_commission'},
                {name:'service_fee'},
                {name:'driver_talqah_commission_percent'},
                {name:'talqah_delivery_commission'},
                {name:'provider_profit'},
                {name:'delivery_profit'},
                {name:'talqah_order_profit'},
                {name:'talqah_delivery_profit'},
                {name:'talqah_total_profit'},
              ]"
            >
            <template slot="date" slot-scope="{data}">
                {{ formatDate(data.item.order_order_time, 'YYYY/MM/DD') }}
              </template>
              <template slot="time" slot-scope="{data}">
                {{ formatDate(data.item.order_order_time, 'hh:mm A') }}
              </template>
              <template slot="payment_method" slot-scope="{data}">
                {{ data.item.payment_method_id }} <!-- TODO: replace it with payment_method name (from backend) -->
              </template>
              <template slot="payment_type" slot-scope="{data}">
                <span @click="onShowPaymentTypeModal(data.item)">
<!--                  {{ data.item.is_cash === 1 ? "نقد" : (data.item.vendor_payment_type === "نقد") ? "آجل" : "نقد" }}--> <!-- TODO: replace it with payment_type name (from backend) -->
                  {{ data.item.is_cash == null ?  (data.item.vendor_payment_type === "نقد" ? "نقد" : "آجل") : (data.item.is_cash === 1 ? "نقد" : "آجل") }} <!-- TODO: replace it with payment_type name (from backend) -->
                </span>
              </template>
              <template slot="order_price" slot-scope="{data}">
                <check-financial-value
                :value="data.item.order_price"
                />
              </template>

              <template slot="delivery_price" slot-scope="{data}">
                <check-financial-value
                :value="data.item.delivery_price"
                />
              </template>


              <template slot="provider_order_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.provider_order_discount"
                />
              </template>

              <template slot="calc_provider_order_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_provider_order_discount"
                />
              </template>

              <template slot="talqah_order_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_order_discount"
                />
              </template>

              <template slot="calc_talqah_order_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_talqah_order_discount"
                />
              </template>

              <template slot="calc_order_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_order_discount"
                />
              </template>

              <template slot="provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.provider_delivery_discount"
                />
              </template>

              <template slot="calc_provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_provider_delivery_discount"
                />
              </template>

              <template slot="talqah_delivery_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_delivery_discount"
                />
              </template>

              <template slot="calc_talqah_delivery_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_talqah_delivery_discount"
                />
              </template>

              <template slot="calc_delivery_discount" slot-scope="{data}">
                <check-financial-value
                :value="data.item.calc_delivery_discount"
                />
              </template>

              <template slot="vendor_talqah_commission_percent" slot-scope="{data}">
                <check-financial-value
                :value="data.item.vendor_talqah_commission_percent"
                />
              </template>

              <template slot="talqah_order_commission" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_order_commission"
                />
              </template>

              <template slot="service_fee" slot-scope="{data}">
                <check-financial-value
                :value="data.item.service_fee"
                />
              </template>

              <template slot="driver_talqah_commission_percent" slot-scope="{data}">
                <check-financial-value
                :value="data.item.driver_talqah_commission_percent"
                />
              </template>

              <template slot="talqah_delivery_commission" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_delivery_commission"
                />
              </template>

              <template slot="provider_profit" slot-scope="{data}">
                <check-financial-value
                :value="data.item.provider_profit"
                />
              </template>

              <template slot="delivery_profit" slot-scope="{data}">
                <check-financial-value
                :value="data.item.delivery_profit"
                />
              </template>

              <template slot="talqah_order_profit" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_order_profit"
                />
              </template>

              <template slot="talqah_delivery_profit" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_delivery_profit"
                />
              </template>

              <template slot="talqah_total_profit" slot-scope="{data}">
                <check-financial-value
                :value="data.item.talqah_total_profit"
                />
              </template>




              <template slot="amount" slot-scope="{ data }">
                <div class="d-flex">
                  {{ formatNumber(data.item.amount) }}
                </div>
              </template>


              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      :to="goToDetail(data.item.order_id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>

            </w-tables>

          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalFinancial"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalFinancial.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
      <edit-payment-type-modal
        :visible="showPaymentTypeModal"
        @close="showPaymentTypeModal=false"
        :order="selectedOrder != null ? selectedOrder : -1"
        @onComplete="()=>loadData()"
      />
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import FinancialCard from "@/components/financialCard";

import {WameedPagination, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CustomBadge from "@/components/customBadge";
import CheckFinancialValue from "@/views/pages/financial/components/checkFinancialValue.vue";
import EditPaymentTypeModal from '@/views/pages/financial/components/editPaymentTypeModal.vue';

export default {
  components: {
    EditPaymentTypeModal,
    CheckFinancialValue,
    CustomBadge,
    FinancialCard,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      showPaymentTypeModal: false,
      selectedOrder: null,
      filterData: {

        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('filters.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },
        {
          title: this.$i18n.t('filters.payment_method'),
          key: "payment_method",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "text",
          data: [
            {
              id: 1,
              title: this.$i18n.t('financial.payment_method.cash_on_delivery'),
            },
            {
              id: 2,
              title: this.$i18n.t('financial.payment_method.wallet'),
            },
          ]
        },
      ],
      fields: [
        {
          key: 'index',
          label: '#',
          sortable: false,
        },

        {key: "date", label: this.$i18n.t('financial.table.date'), tdClass: 'financial_row_item'},
        {key: "time", label: this.$i18n.t('financial.table.time'), tdClass: 'financial_row_item'},
        {key: "order_no", label: this.$i18n.t('financial.table.order_no'), tdClass: 'financial_row_item'},
        {key: "payment_method", label: this.$i18n.t('financial.table.payment_method'), tdClass: 'financial_row_item'},
        {key: "payment_type", label: this.$i18n.t('financial.table.payment_type'), tdClass: 'financial_row_item'},
        {key: "tran_ref", label: this.$i18n.t('financial.table.tran_ref'), tdClass: 'financial_row_item'},
        {key: "type", label: this.$i18n.t('financial.table.type'), tdClass: 'financial_row_item'},

        {key: "vendor_name", label: this.$i18n.t('financial.table.vendor_name'), tdClass: 'financial_row_item'},
        {
          key: 'driver_name',
          label: this.$i18n.t('financial.table.driver_name'),
          sortable: true,
        },
        {key: "order_price", label: this.$i18n.t('financial.table.order_price'), tdClass: 'financial_row_item'},
        {key: "delivery_price", label: this.$i18n.t('financial.table.delivery_price'), tdClass: 'financial_row_item'},


        {key: "discount_type", label: this.$i18n.t('financial.table.discount_type'), tdClass: 'financial_row_item'},
        {
          key: "provider_order_discount",
          label: this.$i18n.t('financial.table.provider_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_provider_order_discount",
          label: this.$i18n.t('financial.table.calc_provider_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_order_discount",
          label: this.$i18n.t('financial.table.talqah_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_talqah_order_discount",
          label: this.$i18n.t('financial.table.calc_talqah_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_order_discount",
          label: this.$i18n.t('financial.table.calc_order_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "provider_delivery_discount",
          label: this.$i18n.t('financial.table.provider_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_provider_delivery_discount",
          label: this.$i18n.t('financial.table.calc_provider_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_delivery_discount",
          label: this.$i18n.t('financial.table.talqah_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_talqah_delivery_discount",
          label: this.$i18n.t('financial.table.calc_talqah_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_delivery_discount",
          label: this.$i18n.t('financial.table.calc_delivery_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "vendor_talqah_commission_percent",
          label: this.$i18n.t('financial.table.vendor_talqah_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_order_commission",
          label: this.$i18n.t('financial.table.talqah_order_commission'),
          tdClass: 'financial_row_item'
        },

        {key: "service_fee", label: this.$i18n.t('financial.table.service_fee'), tdClass: 'financial_row_item'},

        {
          key: "driver_talqah_commission_percent",
          label: this.$i18n.t('financial.table.driver_talqah_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_delivery_commission",
          label: this.$i18n.t('financial.table.talqah_delivery_commission'),
          tdClass: 'financial_row_item'
        },

        {key: "provider_profit", label: this.$i18n.t('financial.table.provider_profit'), tdClass: 'financial_row_item'},
        {key: "delivery_profit", label: this.$i18n.t('financial.table.delivery_profit'), tdClass: 'financial_row_item'},
        {
          key: "talqah_order_profit",
          label: this.$i18n.t('financial.table.talqah_order_profit'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_delivery_profit",
          label: this.$i18n.t('financial.table.talqah_delivery_profit'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_total_profit",
          label: this.$i18n.t('financial.table.talqah_total_profit'),
          tdClass: 'financial_row_item'
        },

        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllFinancial: 'admin/financial/getData',
      getTotalFinancial: 'admin/financial/getTotal',
    }),
  },

  created() {

    this.fillTableFilelds();
    this.loadData();
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.fillTableFilelds();
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      loadFinancial: 'admin/financial/get',
    }),
    onShowPaymentTypeModal(order) {
      this.selectedOrder = order;
      this.showPaymentTypeModal = true;
    },
    exportVendorsToExcel() {

      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });

      this.$store.dispatch('admin/financial/exportExcel',this.filterData)
    },
    fillTableFilelds() {
      // let item = {
      //   key: 'email',
      //   label: this.$i18n.t('table.email'),
      //   sortable: true,
      // };
      // if (this.$route.meta.type == 'vendors') {
      //   this.fields.splice(4, 0, item,);
      //
      // } else {
      //   this.fields = this.fields.filter((item) => {
      //     return item.key !== 'email';
      //   })
      // }
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadFinancial({
        type: this.$route.meta.type,
        ...this.filterData
      });
      console.log(this.getAllFinancial);
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },


    resetFilter() {
      for (let i = 0; i < this.filterContent.length; i++){
        const item = this.filterContent[i];
        this.filterContent[i].startDate = null;
        this.filterContent[i].endDate = null;
        this.filterData['startDate'] = null;
        this.filterData['endDate'] = null;
        console.log(this.filterData[item.key])
      }
      this.loadData();
    },

    orderItems(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

    goToDetail(id) {
     return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    }
  },
};
</script>
