<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('nav.financial.office.penalties_and_tenders')"
      :paths="[{title: $t('nav.financial.office.title'), link: ''}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2" cols="12" style="flex-direction: column-reverse;">
        <wameed-form @onSubmit="submit">
          <b-col cols="12" class="px-0" slot-scope="{ invalid }">
            <b-card no-body class="wameed-card">
              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  <!-- Form fields -->
                  <div class="d-flex flex-wrap">
<!--                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.branch'
                        label="الفرع"
                        placeholder="الفرع"
                        variant='disable'
                        :items='[]'
                        class='wameed_dropdown'
                        title='name'

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>-->
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.type'
                        label="العملية"
                        placeholder="العملية"
                        variant='disable'
                        :items="[
                          {name: 'عطاء', value: 'bonus'},
                          {name: 'جزاء', value: 'penalty'},
                        ]"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col>
                      <wameed-dropdown
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.operation_type'
                        label="نوع العملية"
                        placeholder="نوع العملية"
                        variant='disable'
                        :items="accounts"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.captainName'
                        label="اسم الكابتن"
                        placeholder="اسم الكابتن"
                        variant='disable'
                        :items="captains"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

<!--                    <b-col cols="12" md="4">
                      <Wameed-date-picker
                        v-model="form.date"
                        placeholder="YYYY-MM-DD"
                        :config='{"type":"string","mask":"YYYY-MM-DD"}'
                        mode="date"
                        label="التأريخ"

                      />
                    </b-col>-->
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        v-model="form.currency"
                        label="العملة"
                        placeholder="العملة"
                        variant="disable"
                        :items="currencies"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="amount"
                        v-model="form.amount"
                        label="المبلغ"
                        rules="required|numeric"
                        placeholder="المبلغ"
                        is-append
                        :append-text="getValueAppendText"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <wameed-text-area
                        id="details"
                        v-model="form.details"
                        label="البيان"
                        placeholder="البيان"
                      />
                    </b-col>
                    <b-col cols="12" md="12">

                    </b-col>
                    <b-col cols="12" md="4">


                    </b-col>
                    <b-col cols="12" md="4">

                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>
<!--            <b-card no-body class="wameed-card">
              <b-card-header class="align-items-start tab-title">
                <h5 class="text-med-20 text-font-main mb-0">
                  {{ $t('users.driver.orders_and_delivery_costs') }}
                </h5>
              </b-card-header>
              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  &lt;!&ndash; Form fields &ndash;&gt;
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                        id="numberOfOrders"
                        v-model="form.numberOfOrders"
                        :label="$t('users.driver.form.number_of_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalOrderValue"
                        v-model="form.totalOrderValue"
                        :label="$t('users.driver.form.total_order_value')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDiscountsOnOrders"
                        v-model="form.totalDiscountsOnOrders"
                        :label="$t('users.driver.form.total_discounts_on_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <text-input
                        id="netOrders"
                        v-model="form.netOrders"
                        :label="$t('users.driver.form.net_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="totalDeliveryValue"
                        v-model="form.totalDeliveryValue"
                        :label="$t('users.driver.form.total_delivery_value')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="deliveryDiscounts"
                        v-model="form.deliveryDiscounts"
                        :label="$t('users.driver.form.delivery_discounts')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <text-input
                        id="netDelivery"
                        v-model="form.netDelivery"
                        :label="$t('users.driver.form.net_delivery')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDueFromCustomers"
                        v-model="form.totalDueFromCustomers"
                        :label="$t('users.driver.form.total_due_from_customers')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>-->
<!--            <b-card no-body class="wameed-card">
              <b-card-header class="align-items-start tab-title">
                <h5 class="text-med-20 text-font-main mb-0">
                  {{ $t('users.driver.additions_and_deductions') }}
                </h5>
              </b-card-header>

              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  &lt;!&ndash; Form fields &ndash;&gt;
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                        id="deliveryOfferDeductionRate"
                        v-model="form.deliveryOfferDeductionRate"
                        :label="$t('users.driver.form.delivery_offer_deduction_rate')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="compensationForDeliveryOffers"
                        v-model="form.compensationForDeliveryOffers"
                        :label="$t('users.driver.form.compensation_for_delivery_offers')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="companyShareAfterDeductions"
                        v-model="form.companyShareAfterDeductions"
                        :label="$t('users.driver.form.company_share_after_deductions')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="holidayWorkAllowanceRate"
                        v-model="form.holidayWorkAllowanceRate"
                        :label="$t('users.driver.form.holiday_work_allowance_rate')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="exceptionalCompensations"
                        v-model="form.exceptionalCompensations"
                        :label="$t('users.driver.form.exceptional_compensations')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="penaltiesAndViolations"
                        v-model="form.penaltiesAndViolations"
                        :label="$t('users.driver.form.penalties_and_violations')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalAdditionsAndDeductions"
                        v-model="form.totalAdditionsAndDeductions"
                        :label="$t('users.driver.form.total_additions_and_deductions')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalCaptainsIncomeToday"
                        v-model="form.totalCaptainsIncomeToday"
                        :label="$t('users.driver.form.total_captains_income_today')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalCaptainsIncomeToday"
                        v-model="form.totalCaptainsIncomeToday"
                        :label="$t('users.driver.form.total_captains_income_today')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDueToCompany"
                        v-model="form.totalDueToCompany"
                        :label="$t('users.driver.form.total_due_to_company')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="remainingAvailableBalanceCaptain"
                        v-model="form.remainingAvailableBalanceCaptain"
                        :label="$t('users.driver.form.remaining_available_balance_captain')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>-->
            <div class="d-flex flex-wrap justify-content-between">
              <div class="px-4 py-3">
                <wameed-btn
                  classes="text-med-14 text-white rounded-10"
                  :title="$t('common.save')"
                  :disabled="invalid"
                  type="submit"
                  variant="main"
                />
              </div>
              <div class="px-4 py-3">
                <wameed-btn
                  classes="text-med-14 text-font-secondary rounded-10"
                  :title="$t('common.cancel')"
                  type="button"
                  variant="gray"
                  @onClick="goBack"
                />
              </div>
            </div>
          </b-col>
        </wameed-form>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDatePicker,
  WameedDropdown,
  WameedForm,
  wameedNotify,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm'
import {PaymentMethod} from "@/enums/paymentMethod.enum";
import WameedTextArea from '@/components/wameed/WameedTextArea.vue';

export default {
  components: {
    WameedTextArea,
    WameedDatePicker,
    TextInput,
    wameedNotify,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      paymentMethods: PaymentMethod,
      accounts_list: [],
      form: {
        type: '',
        payment_method_obj: {"id": 1, "value":"cash", "name": "نقد"},
        captains_list: [],
        captainName: '',
        details: '',
        branch: '',
        amount: '',
        operation_type: '',
        date: new Date(),
        insuranceAmount: '',
        wallet: '',
        currency: {'id': 1, 'name': 'ريال يمني'},
        serviceType: '',
        personName: '',
        personNumber: '',
        financialCeiling: '',
        consumedBalance: '',
        remainingInsurance: '',
        availableBalanceToday: '',
        amountDueToday: '',
        captainsEarningsToday: '',
        // orderAndDeliveryCosts: "",
        numberOfOrders: '',
        totalOrderValue: '',
        totalDiscountsOnOrders: '',
        netOrders: '',
        totalDeliveryValue: '',
        deliveryDiscounts: '',
        netDelivery: '',
        totalDueFromCustomers: '',
        additionsAndDeductions: '',
        deliveryOfferDeductionRate: '',
        compensationForDeliveryOffers: '',
        companyShareAfterDeductions: '',
        holidayWorkAllowanceRate: '',
        exceptionalCompensations: '',
        penaltiesAndViolations: '',
        totalAdditionsAndDeductions: '',
        totalCaptainsIncomeToday: '',
        totalDueToCompany: '',
        remainingAvailableBalanceCaptain: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      captains: "admin/financialReports/getCaptains",
      bonusesAccounts: "admin/financialReports/getBonuses",
      penaltiesAccounts: "admin/financialReports/getPenalties",
      currencies: "admin/financialReports/getCurrencies",
    }),

    accounts() {
      if (this.form.type != null || this.form.type !== '') {
        if (this.form.type.value === 'bonus') {
          return this.bonusesAccounts
        } else if (this.form.type.value === 'penalty') {
          return this.penaltiesAccounts
        }
      }

      return [];
      // return (this.type != null || this.type !== '') ?  this.type.value === 'bonus' ? this.bonusesAccounts : this.penaltiesAccounts : [];
    },
    // Net Orders Calculation
    calculatedNetOrders() {
      return this.form.totalOrderValue - this.form.totalDiscountsOnOrders;
    },

    // Net Delivery Calculation
    calculatedNetDelivery() {
      return this.form.totalDeliveryValue - this.form.deliveryDiscounts;
    },

    // Total Additions and Deductions Calculation
    calculatedTotalAdditionsAndDeductions() {
      return (
        this.form.deliveryOfferDeductionRate +
        this.form.compensationForDeliveryOffers +
        this.form.companyShareAfterDeductions +
        this.form.holidayWorkAllowanceRate +
        this.form.exceptionalCompensations -
        this.form.penaltiesAndViolations
      );
    },

    // Total Captain's Income Today Calculation
    calculatedTotalCaptainsIncomeToday() {
      return this.calculatedNetOrders + this.calculatedNetDelivery + this.calculatedTotalAdditionsAndDeductions;
    },

    // Remaining Available Balance for Captain Calculation
    calculatedRemainingAvailableBalanceCaptain() {
      return this.form.availableBalanceToday - this.form.amountDueToday;
    },
    showWalletNameField() {
      if (this.form.payment_method_obj)
        return this.form.payment_method_obj.id === 2;
      return false;
    },

    getValueAppendText() {
      let temp = "";
      if (this.form.currency) {
        temp = this.form.currency.id;
      }
      if (temp === 1) {
        return 'ر.ي';
      }
      if (temp === 2) {
        return 'ر.س';
      }
      if (temp === 3) {
        return '$';
      }
      return ''
    },
  },
  methods: {
    ...mapActions({
      getCaptains: "admin/financialReports/getCaptains",
      getAccounts: "admin/financialReports/getAccounts",
      storeIncome: "admin/financialReports/storeIncome"
    }),
    loadData() {
      this.getCaptains({
        // type: this.$route.meta.type,
        ...this.filterData
      });
      this.getAccounts();
      console.log(this.data);
    },
    submit() {
      // Submit logic with calculated values
      const dataToSubmit = {
        to: this.form.captainName.account_no,
        from: this.form.operation_type.account_no,
        amount: this.form.amount,
        details: this.form.details,
        // date: this.form.date,
        exchangeRate: 1,
        currencyId: this.form.currency.id,
        orderId: null
        // type: 'cash'
      }
      let type = null;
      if (this.form.type.value === 'penalty') {
        type = 'debit';
        dataToSubmit.orderId = -1;
      } else if (this.form.type.value === 'bonus') {
        type = 'credit';
        dataToSubmit.orderId = -2;
      }

      this.storeIncome({ type:type, data: dataToSubmit });
    },
    goBack() {

    }
  },
  async mounted() {
    // await this.loadData();
    this.$store.dispatch("admin/financialReports/getCaptains");
    this.$store.dispatch("admin/financialReports/getAccounts");
    this.form.captains_list = this.captains.map((captain) => {
      return {id: captain.id, account_no: captain.account_no ,name: captain.name_ar}
    });
    this.accounts_list = this.accounts.map((account) => {
      return {account_no: account.account_no ,name: account.name_ar}
    });
  },
  watch: {
    'form.type': function (newVal, oldVal) {
      // Reset the second dropdown when the first dropdown changes
      if (newVal !== oldVal) {
        this.form.operation_type = null;
      }
    },
  },
};
</script>
