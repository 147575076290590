var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('nav.financial.office.penalties_and_tenders'),"paths":[{title: _vm.$t('nav.financial.office.title'), link: ''}],"btn-title":"إضافة","route-name":'create-penalties'}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent,"searchPlaceholder":_vm.$t('common.search') + ' ...',"btnTitle":_vm.$t('common.filter_data'),"menuTitle":_vm.$t('common.order_by') + ' : ',"subTitle":'asdf',"orderByItems":[
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
        {
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        } ]},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData}})],1),_c('section',{staticClass:"\n        wameed-dashboard-page-content_body\n        d-flex\n        flex-column\n        justify-content-between\n        overflow-hidden\n      "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 "},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"page-number":_vm.filterData.page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[
                { name: 'date' } ]},scopedSlots:_vm._u([{key:"date",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.date, "YYYY/MM/DD"))+" ")]}}],null,false,2191827701)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)]),_c('warning-modal',{attrs:{"variant":"main","iconName":"o-toggle-icon","visible":_vm.showModal,"title":_vm.modal.title,"subTitle":_vm.modal.subTitle,"btnTitle":_vm.modal.btn},on:{"close":function($event){_vm.showModal = false},"submitAction":_vm.toggleAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }