export default {
  getData(state) {
    return state.data;
  },
  getTotal(state) {
    return state.total;
  },

  getCaptains(state) {
    return state.captains;
  },

  getVendors(state) {
    return state.vendors;
  },

  getAccounts(state) {
    return state.accounts;
  },

  getTransactions(state) {
    return state.transactions;
  },

  getPenalties(state) {
    return state.penalties;
  },

  getBonuses(state) {
    return state.bonuses;
  },
  getWallets(state) {
    return state.wallets;
  },
  getCurrencies(state) {
    return state.currencies;
  },

  getApplyFor(state) {
    return state.applyFor;
  },


};
