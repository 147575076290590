<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('nav.financial.office.captains_collections')"
      :paths="[{title: $t('nav.financial.office.title'), link: ''}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <wameed-form @onSubmit="submit">
          <b-col lg="12" md="12" class="px-0" slot-scope="{ invalid }">
            <b-card no-body class="wameed-card">
              <b-card-header class="align-items-start tab-title">
                <h5 class="text-med-20 text-font-main mb-0">
                  {{ $t('users.driver.basic_data') }}
                </h5>
              </b-card-header>
              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  <!-- Form fields -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='form.captainName'
                        label="اسم الكابتن"
                        placeholder="اسم الكابتن"
                        variant='disable'
                        :items="captains"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="captainNo"
                        disabled
                        v-model="form.captainName.account_no"
                        label="رقم الحساب"
                        placeholder="رقم الحساب"
                      />
                    </b-col>
<!--                    <b-col cols="12" md="4">
                      <text-input
                        id="financialCeiling"
                        disabled
                        v-model="form.financialCeiling"
                        label="رقم العملية"
                        placeholder="رقم العملية"
                      />
                    </b-col>-->
                    <b-col cols="12" md="4">
                      <Wameed-date-picker
                        v-model="form.date"
                        placeholder="YYYY-MM-DD"
                        :config='{"type":"string","mask":"YYYY-MM-DD"}'
                        mode="date"
                        label="التأريخ"

                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        v-model="form.currency"
                        label="العملة"
                        placeholder="العملة"
                        variant="disable"
                        :items="[
                          {'id': 1, 'name': 'ريال يمني'},
                          {'id': 2, 'name': 'ريال سعودي'},
                          {'id': 3, 'name': 'دولار أمريكي'},
                          ]"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="holdedAmount"
                        v-model="form.holdedAmount"
                        label="المبلغ المقبوض"
                        rules="required|numeric"
                        placeholder="المبلغ المقبوض"
                        is-append
                        :append-text="getValueAppendText"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="captainsEarningsToday"
                        v-model="form.captainName.balance"
                        label="الرصيد لكم/عليكم"
                        :disabled="true"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                        v-model="form.payment_method_obj"
                        label="طريقة الدفع"
                        placeholder="طريقة الدفع"
                        variant="disable"
                        :items="paymentMethods"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
<!--                      <text-input
                        v-if="!showWalletNameField"
                        id="captainsEarningsToday"
                        v-model="form.hold_no"
                        label="رقم سند القبض"
                        rules="required"
                        :placeholder="$t('form.text.placeholder')"
                      />-->
                      <wameed-dropdown
                        v-if="showWalletNameField"
                        v-model="form.wallet"
                        label="اسم المحفظة"
                        placeholder="اسم المحفظة"
                        variant="disable"
                        :items="wallets"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <wameed-text-area
                        id="captainsEarningsToday"
                        v-model="form.details"
                        label="البيان"
                        rules="required"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
<!--                      <ReceiptVoucher />-->
                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>
<!--            <b-card no-body class="wameed-card">
              <b-card-header class="align-items-start tab-title">
                <h5 class="text-med-20 text-font-main mb-0">
                  {{ $t('users.driver.orders_and_delivery_costs') }}
                </h5>
              </b-card-header>
              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  &lt;!&ndash; Form fields &ndash;&gt;
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                        id="numberOfOrders"
                        v-model="form.numberOfOrders"
                        :label="$t('users.driver.form.number_of_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalOrderValue"
                        v-model="form.totalOrderValue"
                        :label="$t('users.driver.form.total_order_value')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDiscountsOnOrders"
                        v-model="form.totalDiscountsOnOrders"
                        :label="$t('users.driver.form.total_discounts_on_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <text-input
                        id="netOrders"
                        v-model="form.netOrders"
                        :label="$t('users.driver.form.net_orders')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="totalDeliveryValue"
                        v-model="form.totalDeliveryValue"
                        :label="$t('users.driver.form.total_delivery_value')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                        id="deliveryDiscounts"
                        v-model="form.deliveryDiscounts"
                        :label="$t('users.driver.form.delivery_discounts')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <text-input
                        id="netDelivery"
                        v-model="form.netDelivery"
                        :label="$t('users.driver.form.net_delivery')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDueFromCustomers"
                        v-model="form.totalDueFromCustomers"
                        :label="$t('users.driver.form.total_due_from_customers')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>-->
<!--            <b-card no-body class="wameed-card">
              <b-card-header class="align-items-start tab-title">
                <h5 class="text-med-20 text-font-main mb-0">
                  {{ $t('users.driver.additions_and_deductions') }}
                </h5>
              </b-card-header>

              <div>
                <b-card-body class="position-relative justify-content-between px-5">
                  &lt;!&ndash; Form fields &ndash;&gt;
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                        id="deliveryOfferDeductionRate"
                        v-model="form.deliveryOfferDeductionRate"
                        :label="$t('users.driver.form.delivery_offer_deduction_rate')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="compensationForDeliveryOffers"
                        v-model="form.compensationForDeliveryOffers"
                        :label="$t('users.driver.form.compensation_for_delivery_offers')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="companyShareAfterDeductions"
                        v-model="form.companyShareAfterDeductions"
                        :label="$t('users.driver.form.company_share_after_deductions')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="holidayWorkAllowanceRate"
                        v-model="form.holidayWorkAllowanceRate"
                        :label="$t('users.driver.form.holiday_work_allowance_rate')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="exceptionalCompensations"
                        v-model="form.exceptionalCompensations"
                        :label="$t('users.driver.form.exceptional_compensations')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="penaltiesAndViolations"
                        v-model="form.penaltiesAndViolations"
                        :label="$t('users.driver.form.penalties_and_violations')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalAdditionsAndDeductions"
                        v-model="form.totalAdditionsAndDeductions"
                        :label="$t('users.driver.form.total_additions_and_deductions')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalCaptainsIncomeToday"
                        v-model="form.totalCaptainsIncomeToday"
                        :label="$t('users.driver.form.total_captains_income_today')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalCaptainsIncomeToday"
                        v-model="form.totalCaptainsIncomeToday"
                        :label="$t('users.driver.form.total_captains_income_today')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="totalDueToCompany"
                        v-model="form.totalDueToCompany"
                        :label="$t('users.driver.form.total_due_to_company')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                        id="remainingAvailableBalanceCaptain"
                        v-model="form.remainingAvailableBalanceCaptain"
                        :label="$t('users.driver.form.remaining_available_balance_captain')"
                        rules="required|numeric"
                        :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                  </div>
                </b-card-body>
              </div>

            </b-card>-->
            <div class="d-flex flex-wrap justify-content-between">
              <div class="px-4 py-3">
                <wameed-btn
                  classes="text-med-14 text-white rounded-10"
                  :title="$t('common.save')"
                  :disabled="invalid"
                  type="submit"
                  variant="main"
                />
              </div>
              <div class="px-4 py-3">
                <wameed-btn
                  classes="text-med-14 text-font-secondary rounded-10"
                  :title="$t('common.cancel')"
                  type="button"
                  variant="gray"
                  @onClick="goBack"
                />
              </div>
            </div>
          </b-col>
        </wameed-form>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDatePicker,
  WameedDropdown,
  WameedForm,
  wameedNotify,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm'
import {PaymentMethod} from "@/enums/paymentMethod.enum";
import WameedTextArea from '@/components/wameed/WameedTextArea.vue';
import ReceiptVoucher from '@/views/pages/financial-office/components/ReceiptVoucher.vue';

export default {
  components: {
    WameedTextArea,
    WameedDatePicker,
    TextInput,
    wameedNotify,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
    ReceiptVoucher
  },
  data() {
    return {
      paymentMethods: PaymentMethod,
      captains_items: [],
      form: {
        payment_method_obj: {"id": 1, "value": "cash", "name": "نقد"},
        captainName: '',
        date: new Date(),
        holdedAmount: '',
        insuranceAmount: '',
        wallet: '',
        details: '',
        currency: {'id': 1, 'name': 'ريال يمني'},
        serviceType: '',
        personName: '',
        personNumber: '',
        financialCeiling: '',
        consumedBalance: '',
        remainingInsurance: '',
        availableBalanceToday: '',
        amountDueToday: '',
        captainsEarningsToday: '',
        // orderAndDeliveryCosts: "",
        numberOfOrders: '',
        totalOrderValue: '',
        totalDiscountsOnOrders: '',
        netOrders: '',
        totalDeliveryValue: '',
        deliveryDiscounts: '',
        netDelivery: '',
        totalDueFromCustomers: '',
        additionsAndDeductions: '',
        deliveryOfferDeductionRate: '',
        compensationForDeliveryOffers: '',
        companyShareAfterDeductions: '',
        holidayWorkAllowanceRate: '',
        exceptionalCompensations: '',
        penaltiesAndViolations: '',
        totalAdditionsAndDeductions: '',
        totalCaptainsIncomeToday: '',
        totalDueToCompany: '',
        remainingAvailableBalanceCaptain: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      captains: "admin/financialReports/getCaptains",
      wallets: "admin/financialReports/getWallets",
      currencies: "admin/financialReports/getCurrencies"
    }),
    // Example calculated fields based on existing data

    // Net Orders Calculation
    calculatedNetOrders() {
      return this.form.totalOrderValue - this.form.totalDiscountsOnOrders;
    },

    // Net Delivery Calculation
    calculatedNetDelivery() {
      return this.form.totalDeliveryValue - this.form.deliveryDiscounts;
    },

    // Total Additions and Deductions Calculation
    calculatedTotalAdditionsAndDeductions() {
      return (
        this.form.deliveryOfferDeductionRate +
        this.form.compensationForDeliveryOffers +
        this.form.companyShareAfterDeductions +
        this.form.holidayWorkAllowanceRate +
        this.form.exceptionalCompensations -
        this.form.penaltiesAndViolations
      );
    },

    // Total Captain's Income Today Calculation
    calculatedTotalCaptainsIncomeToday() {
      return this.calculatedNetOrders + this.calculatedNetDelivery + this.calculatedTotalAdditionsAndDeductions;
    },

    // Remaining Available Balance for Captain Calculation
    calculatedRemainingAvailableBalanceCaptain() {
      return this.form.availableBalanceToday - this.form.amountDueToday;
    },
    showWalletNameField() {
      if (this.form.payment_method_obj)
        return this.form.payment_method_obj.id === 2;
      return false;
    },

    getValueAppendText() {
      let temp = "";
      if (this.form.currency) {
        temp = this.form.currency.id;
      }
      if (temp === 1) {
        return 'ر.ي';
      }
      if (temp === 2) {
        return 'ر.س';
      }
      if (temp === 3) {
        return '$';
      }
      return ''
    },
  },
  methods: {
    ...mapActions({
      getCaptains: "admin/financialReports/getCaptains",
      storeIncome: "admin/financialReports/storeIncome"
    }),

    loadData() {
      this.getCaptains({
        // type: this.$route.meta.type,
        ...this.filterData
      });
    },

    submit() {
      // Submit logic with calculated values
      // const dataToSubmit = {
      //   ...this.form,
      //   netOrders: this.calculatedNetOrders,
      //   netDelivery: this.calculatedNetDelivery,
      //   totalAdditionsAndDeductions: this.calculatedTotalAdditionsAndDeductions,
      //   totalCaptainsIncomeToday: this.calculatedTotalCaptainsIncomeToday,
      //   remainingAvailableBalanceCaptain: this.calculatedRemainingAvailableBalanceCaptain
      // };
      const dataToSubmit = {
        to: this.form.captainName.account_no,
        amount: this.form.holdedAmount,
        details: this.form.details,
        date: this.form.date,
        exchangeRate: 1,
        currencyId: this.form.currency.id,
        type: this.form.payment_method_obj.value
      }

      if (this.form.payment_method_obj.id === 1) {
        dataToSubmit.from = 'SYSTEM-000002'
      } else if (this.form.payment_method_obj.id === 2) {
        dataToSubmit.from = this.form.wallet.account_no
      }

      // Perform the form submission (e.g., via an API call)
      console.log("Form submission data:", dataToSubmit);
      this.storeIncome({ type:'debit', data: dataToSubmit });
      this.loadData();

      this.form.captainName = '';
      this.form.holdedAmount = '';
      this.form.details = '';
      this.form.date = new Date();
      this.form.currency = {'id': 1, 'name': 'ريال يمني'};
      this.form.payment_method_obj = {"id": 1, "value": "cash", "name": "نقد"};
    },
    goBack() {

    }
  },
  created() {
    // this.loadData();
    this.$store.dispatch("admin/financialReports/getCaptains");
    this.captains_items = this.captains.map((captain) => {
      return {id: captain.id, account_no: captain.account_no, name: captain.name_ar}
    });
  },
};
</script>
