//import i18n from "@/libs/i18n";




const PaymentType = [
    {
        id: 1,
        value: 1,
        name: "نقد"// i18n.t('users.client.individual'),
    },
    {
        id: 2,
        value: 0,
        name: "آجل"// i18n.t('users.client.company'),
    },


];

function filterPaymentType(id) {
    return PaymentType.find((item) => item.id === id);
}

export {PaymentType, filterPaymentType};
