import discounts from './discounts';
import discountsVendor from './discountsVendor';
import users from './users';
import notifications from './notifications';
import fees from './fees';
import settings from './settings';
import ads from './ads';
import home from './home';
import reports from './reports';
import financial from './financial';
import financialReports from './financial-reports';
import talqahBusiness from './talqah-business';


export default {
    modules: {
        reports,
        home,
        ads,
        financial,
        settings,
        fees,
        notifications,
        users,
        discounts,
        discountsVendor,
        financialReports,
        talqahBusiness
    },
    namespaced: true
}
