import branches from "./branches";
import orders from "./orders";
import providers from "./providers";
import cities from "./cities";

export default {
    modules: {
        branches,
        orders,
        providers,
        cities
    },
    namespaced: true
}
