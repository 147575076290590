<template>

  <div>
    <!-- Horizontal Scrollable Checkbox Row -->
    <div class="d-flex flex-row text-nowrap overflow-auto gap-4 border rounded p-3 bg-light">
      <b-form-checkbox
        v-for="field in fields"
        :key="field.key"
        class="form-check-inline mx-3"
        v-model="visibleColumns"
        :value="field.key"
        :unchecked-value="null"
      >
        {{ field.label }}
      </b-form-checkbox>
    </div>
<!--    <b-col col="12" md="6">
      <b-dropdown id="toggle-columns" variant="link" toggle-class="text-decoration-none" no-caret class="mb-3 w-100">
        <template #button-content>
          <p style="width: fit-content">الأعمدة</p>
        </template>
        <b-dropdown-form>
          <div class="dropdown-scroll">
            <b-form-checkbox
              v-for="field in fields"
              :key="field.key"
              class="d-flex w-100"
              v-model="visibleColumns"
              :value="field.key"
              :unchecked-value="null"
            >
              {{ field.label }}
            </b-form-checkbox>
          </div>
        </b-dropdown-form>
      </b-dropdown>
    </b-col>-->

    <b-table
      no-data-title="لا توجد بيانات"
      no-data-subtitle="لم يتم العثور على بيانات"
      :foot-clone="true"
      no-footer-sorting
      responsive
      :items="suppliers"
      :fields="computedFields"
    >
      <!-- Row Data -->
      <template #cell(number)="data">{{ data.index + 1 }}</template>
      <template #cell(service_type)="data">{{ data.item.service_type }}</template>
      <template #cell(company_name)="data">{{ data.item.company_name }}</template>
      <template #cell(total_orders)="data">{{ data.item.total_orders }}</template>
      <template #cell(total_delivery)="data">{{ data.item.total_delivery }}</template>
      <template #cell(service_fee_total)="data">{{ data.item.service_fee_total }}</template>
      <template #cell(grand_total)="data">{{ data.item.grand_total }}</template>
      <template #cell(discount_supplier)="data">{{ data.item.discount_supplier }}</template>
      <template #cell(discount_company)="data">{{ data.item.discount_company }}</template>
      <template #cell(discount_total)="data">{{ data.item.discount_total }}</template>
      <template #cell(company_percentage)="data">{{ data.item.company_percentage }}</template>
      <template #cell(company_value)="data">{{ data.item.company_value }}</template>
      <template #cell(amount_due)="data">{{ data.item.amount_due }}</template>
      <template #cell(cash)="data">{{ data.item.cash }}</template>
      <template #cell(credit)="data">{{ data.item.credit }}</template>
      <template #cell(notes)="data">{{ data.item.notes }}</template>

      <!-- Footer Data -->
      <template #foot(service_type)></template>
      <template #foot(company_name)>{{ $t('table.total') }}</template>
      <template #foot(total_orders)>{{ totalOrders }}</template>
      <template #foot(total_delivery)>{{ totalDelivery }}</template>
      <template #foot(service_fee_total)>{{ totalServiceFee }}</template>
      <template #foot(grand_total)>{{ grandTotal }}</template>
      <template #foot(discount_supplier)>{{ totalDiscountSupplier }}</template>
      <template #foot(discount_company)>{{ totalDiscountCompany }}</template>
      <template #foot(discount_total)>{{ totalDiscount }}</template>
      <template #foot(company_percentage)>{{ totalCompanyPercentage }}</template>
      <template #foot(company_value)>{{ totalCompanyValue }}</template>
      <template #foot(amount_due)>{{ totalAmountDue }}</template>
      <template #foot(cash)>{{ totalCash }}</template>
      <template #foot(credit)>{{ totalCredit }}</template>
      <template #foot(notes)></template>
    </b-table>
  </div>


</template>

<script>
export default {
  data() {
    return {
      suppliers: [
        {
          service_type: 'مطاعم',
          company_name: 'مطاعم الأرض الخضراء',
          total_orders: 10000,
          total_delivery: 2000,
          service_fee_total: 1500,
          grand_total: 13500,
          discount_supplier: 500,
          discount_company: 100,
          discount_total: 600,
          company_percentage: '10%',
          company_value: 1350,
          amount_due: 12150,
          cash: 60,
          credit: 20,
          notes: ''
        },
        {
          service_type: 'مطاعم',
          company_name: 'فلافل المعلم (فرع حدة المدينة)',
          total_orders: 10000,
          total_delivery: 2000,
          service_fee_total: 1500,
          grand_total: 13500,
          discount_supplier: 500,
          discount_company: 100,
          discount_total: 600,
          company_percentage: '10%',
          company_value: 1350,
          amount_due: 12150,
          cash: 60,
          credit: 20,
          notes: ''
        },
        {
          service_type: 'مطاعم',
          company_name: 'بيت جدي',
          total_orders: 10000,
          total_delivery: 2000,
          service_fee_total: 1500,
          grand_total: 13500,
          discount_supplier: 500,
          discount_company: 100,
          discount_total: 600,
          company_percentage: '10%',
          company_value: 1350,
          amount_due: 12150,
          cash: 60,
          credit: 20,
          notes: ''
        },
        {
          service_type: 'مطاعم',
          company_name: '99 ninety - nine',
          total_orders: 10000,
          total_delivery: 2000,
          service_fee_total: 1500,
          grand_total: 13500,
          discount_supplier: 500,
          discount_company: 100,
          discount_total: 600,
          company_percentage: '10%',
          company_value: 1350,
          amount_due: 12150,
          cash: 60,
          credit: 20,
          notes: ''
        }
      ],
      // Computed totals for the footer
      totalOrders: 0,
      totalDelivery: 0,
      totalServiceFee: 0,
      grandTotal: 0,
      totalDiscountSupplier: 0,
      totalDiscountCompany: 0,
      totalDiscount: 0,
      totalCompanyPercentage: 0,
      totalCompanyValue: 0,
      totalAmountDue: 0,
      totalCash: 0,
      totalCredit: 0,
      fields: [
        // { key: 'number', label: '#' },
        { key: 'service_type', label: 'الخدمة' },
        { key: 'company_name', label: 'إسم الشركة' },
        { key: 'total_orders', label: 'إجمالي الطلبات' },
        { key: 'total_delivery', label: 'إجمالي التوصيل' },
        { key: 'service_fee_total', label: 'إجمالي رسوم الخدمة' },
        { key: 'grand_total', label: 'الإجمالي' },
        { key: 'discount_supplier', label: 'من المورد' },
        { key: 'discount_company', label: 'من شركة طلقة' },
        { key: 'discount_total', label: 'الإجمالي' },
        { key: 'company_percentage', label: 'النسبة المستحقة لشركة طلقة' },
        { key: 'company_value', label: 'قيمة النسبة المستحقة لشركة طلقة' },
        { key: 'amount_due', label: 'المبلغ المستحق لمزود الخدمة بعد الخصم' },
        { key: 'cash', label: 'نوع الدفع لمزود الخدمة (نقد)' },
        { key: 'credit', label: 'نوع الدفع لمزود الخدمة (آجل)' },
        { key: 'notes', label: 'ملاحظات' }
      ],
      // Initialize visibleColumns with all keys from fields
      visibleColumns: [],
    };
  },
  computed: {
    computedFields() {
      return this.fields.filter(field => this.visibleColumns.includes(field.key));
      // return [
      //   { key: 'number', label: '#' },
      //   { key: 'service_type', label: 'الخدمة' },
      //   { key: 'company_name', label: 'إسم الشركة' },
      //   { key: 'total_orders', label: 'إجمالي الطلبات' },
      //   { key: 'total_delivery', label: 'إجمالي التوصيل' },
      //   { key: 'service_fee_total', label: 'إجمالي رسوم الخدمة' },
      //   { key: 'grand_total', label: 'الإجمالي' },
      //   { key: 'discount_supplier', label: 'من المورد' },
      //   { key: 'discount_company', label: 'من شركة طلقة' },
      //   { key: 'discount_total', label: 'الإجمالي' },
      //   { key: 'company_percentage', label: 'النسبة المستحقة لشركة طلقة' },
      //   { key: 'company_value', label: 'قيمة النسبة المستحقة لشركة طلقة' },
      //   { key: 'amount_due', label: 'المبلغ المستحق لمزود الخدمة بعد الخصم' },
      //   { key: 'cash', label: 'نوع الدفع لمزود الخدمة (نقد)' },
      //   { key: 'credit', label: 'نوع الدفع لمزود الخدمة (آجل)' },
      //   { key: 'notes', label: 'ملاحظات' }
      // ];
    }
  },
  created() {
    // Initialize visibleColumns with all field keys
    this.visibleColumns = this.fields.map(field => field.key);
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

/* Style for scrollable dropdown */
.dropdown-scroll {
  max-height: 200px; /* Set your desired fixed height */
  overflow-y: auto; /* Add vertical scrolling */
}
</style>
