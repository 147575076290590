import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/libs/i18n';
import {localize} from 'vee-validate';
import moment from 'moment';

import store from '@/store';
import authRoutes from './routes/auth';
import adminRoutes from './routes/admin';
import settingsRoutes from './routes/settings';
import middleware from "@/router/middleware/middleware";
import {permissions} from "@/enums/permissions.enum";
import {wameedLang} from "wameed-ui/dist/wameed-ui.esm";


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: '/',
            redirect: `/${i18n.locale}/`,
        },
        {
            path: '/:lang',
            component: {
                render(c) {
                    return c('router-view');
                },
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    redirect: `/${i18n.locale}/dashboard`,
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/pages/dashboard/dashboard.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },
                {
                    path: 'ads',
                    name: 'ads',
                    component: () => import('@/views/pages/ads/index.vue'),
                    meta: {
                        middleware: [middleware],
                        permissions: [permissions.readAds],
                        pagePermissions: {
                            create: permissions.addAds,
                            edit: permissions.editAds,
                            read: permissions.readAds,
                            delete: permissions.deleteAds
                        }
                    },
                },

                {
                    path: 'talqah-business',
                    name: 'talqah-business',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: 'orders',
                            component: {
                                render(c) {
                                    return c('router-view');
                                },
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'talqah-business-orders',
                                    component: () => import("@/views/pages/talqah_business/orders/index.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readOrders],
                                        pagePermissions: {
                                            edit: permissions.editOrders,
                                            read: permissions.readOrders,
                                        }
                                    },
                                },

                                {
                                    path: 'create',
                                    name: 'talqah-business-orders-create',
                                    component: () => import('@/views/pages/talqah_business/orders/create_update.vue'),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.editOrders],
                                        pagePermissions: {
                                            edit: permissions.editOrders,
                                            read: permissions.readOrders,
                                        }
                                    }
                                },

                                {
                                    path: 'detail/:id',
                                    name: 'talqah-business-order-detail',
                                    component: () => import('@/views/pages/talqah_business/orders/detail.vue'),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readOrders],
                                        pagePermissions: {
                                            edit: permissions.editOrders,
                                            read: permissions.readOrders,
                                        }
                                    },
                                }
                            ]
                        },

                        {
                            path: 'providers',
                            component: {
                                render(c) {
                                    return c('router-view');
                                },
                            },

                            children: [
                                {
                                    path: '',
                                    name: 'talqah-business-providers',
                                    component: () => import("@/views/pages/talqah_business/providers/index.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                            read: permissions.readCompaniesInfo
                                        }
                                    },
                                },



                                {
                                    path: 'detail/:id',
                                    name: 'talqah-business-providers-detail',
                                    component: () => import("@/views/pages/talqah_business/providers/detail.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },

                                {
                                    path: 'create',
                                    name: 'talqah-business-providers-create',
                                    component: () => import("@/views/pages/talqah_business/providers/create_update.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },

                                {
                                    path: 'update/:id',
                                    name: 'talqah-business-providers-update',
                                    component: () => import("@/views/pages/talqah_business/providers/create_update.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },
                            ]
                        },

                        {
                            path: 'branches',
                            component: {
                                render(c) {
                                    return c('router-view');
                                },
                            },

                            children: [
                                {
                                    path: '',
                                    name: 'talqah-business-branches',
                                    component: () => import("@/views/pages/talqah_business/branches/index.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                            read: permissions.readCompaniesInfo
                                        }
                                    },
                                },



                                {
                                    path: 'detail/:id',
                                    name: 'talqah-business-branches-detail',
                                    component: () => import("@/views/pages/talqah_business/branches/detail.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },

                                {
                                    path: 'create',
                                    name: 'talqah-business-branches-create',
                                    component: () => import("@/views/pages/talqah_business/branches/create_update.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },

                                {
                                    path: 'update/:id',
                                    name: 'talqah-business-branches-update',
                                    component: () => import("@/views/pages/talqah_business/branches/create_update.vue"),
                                    meta: {
                                        middleware: [middleware],
                                        permissions: [permissions.readCompaniesInfo, permissions.readCompaniesFinancials, permissions.readCompaniesProducts, permissions.readCompaniesCategories, permissions.readCompaniesOrders, permissions.readCompaniesReviews],
                                        pagePermissions: {
                                            create: permissions.addCompanies,
                                            edit: permissions.editCompanies,
                                        }
                                    },
                                },
                            ]
                        },
                    ]
                },

                {
                    path: 'orders',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'orders',
                            component: () => import('@/views/pages/orders/index.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readOrders],
                                pagePermissions: {
                                    edit: permissions.editOrders,
                                    read: permissions.readOrders,
                                }
                            },
                        },

                        {
                            path: 'detail/:id',
                            name: 'order-detail',
                            component: () => import('@/views/pages/orders/detail.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readOrders],
                                pagePermissions: {
                                    edit: permissions.editOrders,
                                    read: permissions.readOrders,
                                }
                            },
                        },

                    ],
                },


                {
                    path: 'discounts',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'discounts',
                            component: () => import('@/views/pages/discounts/index.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readDiscounts],
                                pagePermissions: {
                                    create: permissions.addDiscounts,
                                    edit: permissions.editDiscounts,
                                    read: permissions.readDiscounts,
                                    delete: permissions.deleteDiscounts
                                }
                            },
                        },
                        {
                            path: 'create',
                            name: 'discounts-create',
                            component: () => import('@/views/pages/discounts/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.addDiscounts],
                            },
                        },
                        {
                            path: 'update/:id',
                            name: 'discounts-update',
                            component: () => import('@/views/pages/discounts/create_update.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.editDiscounts],
                            },
                        },
                        {
                            path: 'detail/:id',
                            name: 'discounts-detail',
                            component: () => import('@/views/pages/discounts/detail/detail.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readDiscounts],
                                pagePermissions: {
                                    read: permissions.readDiscounts,
                                    edit: permissions.editDiscounts,
                                }
                            },
                        },
                    ],
                },

                {
                    path: 'discounts-vendors',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'discounts-vendors',
                            component: () => import('@/views/pages/discountsVendor/index.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readDiscounts],
                                pagePermissions: {
                                    create: permissions.addDiscounts,
                                    edit: permissions.editDiscounts,
                                    read: permissions.readDiscounts,
                                    delete: permissions.deleteDiscounts
                                }
                            },
                        },
                        {
                            path: 'detail/:id',
                            name: 'discounts-vendors-detail',
                            component: () => import('@/views/pages/discountsVendor/detail/detail.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readDiscounts],
                                pagePermissions: {
                                    read: permissions.readDiscounts,
                                    edit: permissions.editDiscounts,
                                }
                            },
                        },
                    ],
                },

                {
                    path: 'financial-office',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },

                    children: [
                        {
                            path: 'penalties',
                            name: 'penalties',
                            component: () => import('@/views/pages/financial-office/penalties_and_tenders.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readFinancials],
                            },
                        },
                        {
                            path: 'create-penalties',
                            name: 'create-penalties',
                            component: () => import('@/views/pages/financial-office/penalties_create.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readFinancials],
                            },
                        },
                        {
                            path: 'captains-collection',
                            name: 'captains-collection',
                            component: () => import('@/views/pages/financial-office/captains_create.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readFinancials],
                            },
                        },
                        {
                            path: 'vendors-payments',
                            name: 'vendors-payments',
                            component: () => import('@/views/pages/financial-office/vendors_create.vue'),
                            meta: {
                                middleware: [middleware],
                                permissions: [permissions.readFinancials],
                            },
                        },
                    ]
                },


                {
                    path: 'financial',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'financial-all',
                            component: () => import('@/views/pages/financial/index.vue'),
                            meta: {
                                type: 'all',
                                middleware: [middleware],
                                permissions: [permissions.readFinancials],
                            },
                        },

                    ],
                },

                {
                    path: 'financial-reports',
                    component: {
                        render(c) {
                            return c('router-view');
                        },
                    },
                    children: [
                        {
                            path: 'drivers-reports',
                            name: 'drivers-reports',
                            component: () => import('@/views/pages/financial-reports/drivers/detail.vue')
                        },
                        {
                            path: 'vendors-reports',
                            name: 'vendors-reports',
                            component: () => import('@/views/pages/financial-reports/vendors/detail.vue')
                        },
                    ],
                },

                {
                    path: 'notification',
                    name: 'notification',
                    component: () => import('@/views/pages/dashboard/notification.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },


                {
                    path: 'reports',
                    name: 'reports',
                    component: () => import('@/views/pages/reports/index.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },

                {
                    path: 'chat',
                    name: 'chat',
                    component: () => import('@/views/chats/index.vue'),
                    meta: {
                        middleware: [middleware],
                    },
                },
                {
                    path: 'reviews',
                    name: 'reviews',

                    component: () => import('@/views/pages/reviews/index.vue'),
                    meta: {
                        middleware: [middleware],

                        permissions: [permissions.readReviews],
                        pagePermissions: {
                            edit: permissions.editReviews,
                            read: permissions.readReviews,
                            readOrder: permissions.readOrders,
                            delete: permissions.deleteReviews,
                        }
                    },
                },
                {
                    path: 'reviews/:id',
                    name: 'reviews-detail',

                    component: () => import('@/views/pages/reviews/detail.vue'),
                    meta: {
                        middleware: [middleware],

                        permissions: [permissions.readOrders],
                        pagePermissions: {
                            edit: permissions.editOrders,
                            read: permissions.readOrders,
                        }
                    },
                },


                ...settingsRoutes,
                ...authRoutes,
                ...adminRoutes,
            ],
        },

        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeResolve(async (to, from, next) => {
    const languages = ['ar', 'en'];
    if (languages.includes(to.params.lang)) {
        localize(to.params.lang);
        wameedLang(to.params.lang);
        moment.locale(to.params.lang); // 'en'

        store.commit('TOGGLE_RTL', to.params.lang);
        await store.dispatch('setCurrentLocale', to.params.lang);

        i18n.locale = to.params.lang;

        if (to.meta.permissions) {

            if (store.getters['profile/getPermissions'].length === 0) {
                await store.dispatch('profile/basic')
            }
            if (!store.getters['profile/getPermissions'].some((value) => to.meta.permissions.includes(value))) {
                next({name: 'dashboard', params: {lang: i18n.locale}});
            }
        }
        if (to.meta.middleware) {
            const middleware = Array.isArray(to.meta.middleware)
                ? to.meta.middleware
                : [to.meta.middleware];

            const context = {
                from,
                next,
                router,
                to,
            };
            const nextMiddleware = nextFactory(context, middleware, 1);

            return middleware[0]({...context, next: nextMiddleware});
        }
        return next();
    } else {
        if (to.name) next({name: to.name, params: {lang: i18n.locale}});
        else next({name: 'dashboard', params: {lang: i18n.locale}});
    }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }

    if (to.meta.pagePermissions) {

        if (to.meta.pagePermissions.create && !store.getters['profile/getPermissions'].includes(to.meta.pagePermissions.create)) {
            removeElementsFromDom('button-header-create');
        }

        if (to.meta.pagePermissions.read && !store.getters['profile/getPermissions'].includes(to.meta.pagePermissions.read)) {
            removeElementsFromDom('permission-show-detail');
        }

        if (to.meta.pagePermissions.delete && !store.getters['profile/getPermissions'].includes(to.meta.pagePermissions.delete)) {
            removeElementsFromDom('permission-delete-item');
        }

        if (to.meta.pagePermissions.edit && !store.getters['profile/getPermissions'].includes(to.meta.pagePermissions.edit)) {
            removeElementsFromDom('permission-edit-item');
        }


    }


});

export function removeElementsFromDom(className) {
    setTimeout(() => {
        let elements = document.getElementsByClassName(className)
        elements.forEach((el) => {
            el.parentNode.removeChild(el)
        })
    }, 0)
}

export default router;
