<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
        :title="isUpdate?$t('talqah_business.orders.update_btn'):$t('talqah_business.orders.new_btn')"
        :paths="[
          {
            title:$t('nav.talqah_business.title'),
          },
          {
            title:$t('nav.talqah_business.orders.title'),
            link:'talqah-business-orders'
          },
        ]"
    />
    <section class='wameed-dashboard-page-content_body products_create'>
      <b-row class='px-2'>
        <b-col lg='12' md='12' class='px-0'
               :key="key">
          <b-card no-body class='wameed-card'>
            <b-card-header class="align-items-center tab-title">
              <h5 class='text-med-20 text-font-main mb-0'>
                {{ $t('talqah_business.orders.form.title') }}
              </h5>
              <div class="text-main text-md-20 cursor-pointer" v-if="isUpdate"
                   @click="resetPasswordModal=true">
                {{ $t('settings.system_users.reset_password') }}
              </div>
            </b-card-header>
            <wameed-form @onSubmit='submit'>
              <div slot-scope='{ invalid }'>
                <b-card-body
                    class='position-relative justify-content-between px-5'
                >
                  <!-- form -->
                  <div class='d-flex flex-wrap'>
                    <b-col cols='12' md='6'>
                      <text-input
                          id='customer_name'
                          v-model='form.customer_name'
                          :label="$t('talqah_business.orders.form.customer_name')"
                          rules='required'
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>

                    <b-col cols='12' md='6'>
                      <TextInput
                        v-model='form.customer_phone'
                        field-classes='w-100'
                        rules='required'
                        :label="$t('form.phone.label')"
                        :placeholder="$t('form.text.placeholder')"
                        type='number'
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        :searchable="true"
                        :loadingMore="$t('common.loadingMore')"
                        :no_options_search="$t('common.no_options_search')"
                        v-model='provider'
                        label="المزود"
                        placeholder="المزود"
                        variant='disable'
                        :items="getProviders"
                        class="wameed_dropdown"
                        title="name"
                        rules='required'
                        @input="onProviderChange"
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        :no_options_search="$t('common.no_options_search')"
                        v-model='branch'
                        label="الفرع"
                        placeholder="الفرع"
                        variant='disable'
                        :items="branches"
                        class="wameed_dropdown"
                        title="name"

                        :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <div @click='showMapModal = true'>
                        <text-input
                          id='map-address'
                          v-model='latLng'
                          readonly
                          name='location'
                          rules='required'
                          :label="$t('form.address.geoLocation')"
                          :placeholder="$t('form.address.geoLocation_placeholder')"
                          append-class='append-btn append-gray'
                          is-append
                          :append-text="$t('btn.add')"
                        />
                      </div>
                    </b-col>

                    <b-col cols='12' md='4'>
                      <TextInput
                        v-model='form.cost'
                        field-classes='w-100'
                        rules='required'
                        :label="'التكلفة'"
                        :placeholder="'التكلفة'"
                        type='number'
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <Wameed-date-picker
                        v-model="form.delivery_date_time"
                        placeholder="YYYY-MM-DD"
                        :config='{"type":"string","mask":"YYYY-MM-DD HH:mm"}'
                        mode="dateTime"
                        :label="'وقت التوصيل'"
                        rules="required"
                        :minDate="new Date().toDateString()"
                      />
                    </b-col>

                    <b-col cols='12' md='12'>
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-wrap">
                          <b-col cols='12' md='6'>
                            <div v-for="(product, index) in form.products" :key="index" class="product-fields mb-3">
                              <div class="row align-items-center">
                                <!-- Product Name Input -->
                                <div class="col-md-5">
                                  <TextInput
                                    v-model="product.name"
                                    rules="required"
                                    :placeholder="'اسم المنتج'"
                                    type="text"
                                  />
                                </div>

                                <!-- Product Quantity Input -->
                                <div class="col-md-5">
                                  <TextInput
                                    v-model="product.quantity"
                                    rules="required|numeric"
                                    :placeholder="'الكمية'"
                                    type="number"
                                  />
                                </div>

                                <!-- Remove Product Button (Hidden for First Product) -->
                                <div class="col-md-2 text-end">
                                  <div
                                    v-if="index > 0"
                                    class="remove-icon d-flex align-items-center text-danger"
                                    @click="removeProduct(index)"
                                    role="button"
                                  >
                                    <trash-icon class="me-1" />
                                    {{ $t('btn.delete') }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Add Product Button -->
                            <div
                              @click="addProduct"
                              class="outline-link d-inline-block mt-3"
                              role="button"
                            >
                              <span class="mx-2">إضافة منتج</span>
                              <add-circle-icon color="red" class="mx-2" />
                            </div>

                          </b-col>
                        </div>
                      </div>
                    </b-col>

                    <b-col cols='12' md='4' class="mt-5">
                      <b-form-checkbox v-model="form.is_paid" name="check-button" switch>
                        مدفوع
                      </b-form-checkbox>
                    </b-col>

                    <b-col cols='12' md='4' class="z-100">
<!--                      <wameed-image-cropper-input
                          :label="$t('users.vendor.form.profile_bg')"
                          :placeholder="$t('form.image.placeholder')"
                          :uploaded-image="form.temp_profile_bg"
                          :get-object="true"
                          :fixed="true"
                          @changed="uploadBackgroundImage"
                          :width="323"
                          :height="180"
                      />-->
<!--                      <wameed-input-file-upload-->
<!--                          fileType='images'-->
<!--                          srcName='url'-->
<!--                          field-classes='w-100 z-100'-->
<!--                          functionType='front'-->
<!--                          :value="form.temp_profile_bg&&form.temp_profile_bg.length>0?form.temp_profile_bg[0].url:''"-->
<!--                          :uploadedFiles='form.temp_profile_bg'-->
<!--                        -->
<!--                          :input-placeholder=""-->
<!--                          :input-append-text="$t('form.image.browse')"-->
<!--                          @changed='uploadBackgroundImage'-->
<!--                          @deleteImage='deleteBackgroundImage'-->
<!--                      />-->
                    </b-col>

<!--                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        v-model='form.payment_type'
                        :label="$t('users.vendor.form.contract_type')"
                        :placeholder="$t('users.vendor.form.contract_type')"
                        variant='disable'

                        :items="payment_types"
                        class='wameed_dropdown'
                        title='name'
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>-->

<!--                    <b-col cols='12' md='4'>
                      <TextInput
                          v-model='form.talqah_commission'
                          field-classes='w-100'
                          :label="$t('users.vendor.form.talqah_commission')"
                          :placeholder="$t('form.text.placeholder')"
                          is-append
                          type='number'
                          rules='required'
                          append-text='%'
                      />
                    </b-col>-->
                  </div>


                </b-card-body>
                <b-card-footer class='px-4 py-3'>
                  <div class='d-flex flex-wrap justify-content-between'>
                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-white rounded-10'
                          :title="isUpdate?$t('talqah_business.orders.update_btn'):$t('talqah_business.orders.new_btn')"
                          :disabled='invalid'
                          type='submit'
                          variant='main'
                      />
                    </div>

                    <div class='px-4 py-3'>
                      <wameed-btn
                          classes='  text-med-14 text-font-secondary rounded-10 '
                          :title="$t('common.cancel')"
                          type='button'
                          variant='gray'
                          @onClick='goBack'
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <map-modal
        @setLocation='setLocation'
        :visible='showMapModal'
        @close='showMapModal = false'
    />


    <warning-modal
        variant='main'
        iconName='o-sent-icon'
        :visible='showSentModal'
        @close='goBack'
        @submitAction='goBack'
        :title="$t('users.vendor.sent.title')"
        :subTitle="$t('users.vendor.sent.subTitle')"
        :btnTitle="$t('common.OK')"
    />

    <warning-modal
        variant='warning'
        iconName='o-warning-icon'
        :visible="resetPasswordModal"
        @close="resetPasswordModal = false"
        @submitAction="resetPasswordAction"
        :title="$t('settings.system_users.rest_modal.title')"
        :subTitle="$t('settings.system_users.rest_modal.desc')"
        :btnTitle="$t('btn.ok')"
    />
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm';
import MapModal from '@/components/mapModal';
import WarningModal from '@/components/WarningModal';
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";
import WameedDatePicker from '@/components/wameed/WameedDatePicker.vue';
import WameedSwitch from '@/components/wameed/WameedSwitch.vue';

export default {
  components: {
    WameedSwitch,
    WameedDatePicker,
    WameedImageCropperInput,
    WarningModal,
    MapModal,
    TextInput,
    TextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader
  },
  data() {
    return {
      key: 1,
      showMapModal: false,
      resetPasswordModal: false,
      showSentModal: false,
      provider: null,
      branches: [],
      branch: null,
      latLng: null,
      form: {
        customer_name: null,
        customer_phone: null,
        branch_id: null,
        to_lat: null,
        to_lng: null,
        products: [
          {name: '', quantity: ''}
        ],
        cost: null,
        delivery_date_time: new Date(),
        is_paid: false,
      }

    };
  },
  computed: {
    ...mapGetters({
      getProviders: 'admin/talqahBusiness/providers/getData',
      getBranches: 'admin/talqahBusiness/branches/getData',
    }),


    showOrderTime() {
      return this.form.category.id !== 3;
    },

    maxOrderTime() {
      let value = this.form.min_order_time;
      if (value) {
        return '|min_value:' + value
      }
      return '';
    },

    tempProfileImageValue() {
      if (this.form.temp_profile_image.length > 0)
        return this.form.temp_profile_image[0].url
      return '';
    },
    tempBgImageValue() {
      if (this.form.temp_profile_bg && this.form.temp_profile_bg.length > 0)
        return this.form.temp_profile_bg[0].url
      return '';
    },
    isUpdate() {
      return (this.$route.name === 'users-vendors-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {
    console.log(this.$store);
    await this.$store.dispatch('admin/talqahBusiness/providers/get');

    if (this.$route.name === 'users-vendors-update' && this.$route.params.id) {
      this.$store.dispatch('admin/users/simpleDetail', {id: this.$route.params.id, type: 'vendors'}).then(async () => {

        await this.$store.dispatch('admin/users/getVendorClassification', this.userDetail.category_id);

        this.form = this.userDetail;
        if (this.userDetail.profile_image)
          this.form.temp_profile_image = {url: this.userDetail.profile_image,name: this.userDetail.profile_image};
        if (this.userDetail.profile_bg)
          this.form.temp_profile_bg = {url: this.userDetail.profile_bg,name:this.userDetail.profile_bg};

        this.form.category = this.getVendorCategories.find((cat) => cat.id === this.userDetail.category_id);
        this.form.classifications = this.getVendorClassification.filter((cat) => this.userDetail.classifications_ids.includes(cat.id));
      });

    } else {
      await this.$store.dispatch('admin/users/unsetDetail');
    }
  },


  methods: {
    addProduct() {
      this.form.products.push({
        name: '',
        quantity: ''
      });
    },
    removeProduct(index) {
      this.form.products.splice(index, 1);
    },
    toggleIsPaid() {
      this.form.is_paid = !this.form.is_paid;
    },
    uploadProfileImage(value) {
      this.key++;
      this.form.temp_profile_image = value;
    },
    deleteProfileImage() {
      this.key++;
      this.form.temp_profile_image = [];
    },
    uploadBackgroundImage(value) {
      this.key++;
      this.form.temp_profile_bg = value;
    },
    deleteBackgroundImage() {
      this.key++;
      this.form.temp_profile_bg = [];
    },
    setLocation(value) {
      // this.form.address = value.address;
      this.latLng = value.location;
      let [lat, lng] = this.latLng.split(',');
      this.form.to_lat = lat;
      this.form.to_lng = lng;
      this.showMapModal = false;
    },
    goBack() {
      this.$router.push({name: 'talqah-business-orders'});
    },

    async onProviderChange() {
      this.branch = null;
      this.branches = [];
      await this.$store.dispatch('admin/talqahBusiness/branches/get', this.provider.id);
      this.branches = this.getBranches.map((branch) => {
        return {id: branch.id ,name: branch.name_ar}
      });
    },
    resetPasswordAction() {
      this.$store.dispatch('admin/users/resetVendorPassword', this.$route.params.id).then((item) => {
        if (item === 1) {
          setTimeout(() => {
            this.showSentModal = true
          }, 500)
        }
      }).finally(() => {
        this.resetPasswordModal = false;
      });
    },
    submit() {

      this.form.branch_id = this.branch.id;

      if (this.$route.name === 'talqah-business-orders-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/talqahBusiness/orders/update', this.form);
      } else {
        this.$store.dispatch('admin/talqahBusiness/orders/create', this.form);
      }

    }
  },

}
;
</script>

<style scoped>
.imageHolder {
  z-index: 99;
}
</style>
