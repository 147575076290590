<template>
  <div class="order-detail--card mb-5">
    <div class="order-detail-info">


      <div class="flex">
        <div class="tile-text">
          <div class="text">التاريخ</div>
          <div class="value">{{ formattedDate }}</div>
        </div>
        <div class="tile-text">
          <div class="text">اليوم</div>
          <div class="value">{{ dayNameArabic }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text">
          <div class="text">عدد الطلبات</div>
          <div class="value">{{ formatNumber(1000000, false) }}</div>
        </div>
        <div class="tile-text">
          <div class="text">المدفوع نقداً</div>
          <div class="value">{{ formatNumber(1000000) }}</div>
        </div>
        <div class="tile-text">
          <div class="text">مستحق آجل</div>
          <div class="value">{{ formatNumber(1000000) }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text">
          <div class="text">إجمالي مبلغ الطلبات <br> <span class="small">(المستحقة لمزود الخدمة)</span></div>
          <div class="value">
            <check-price :price="200000" />
          </div>
        </div>
        <div class="tile-text">
          <div class="text">إجمالي المبلغ المستحق بعد الخصومات</div>
          <div class="value">
            <check-price :price="200000" />
          </div>
        </div>
      </div>


    </div>

    <div class="h-divider"></div>
    <div class="d-flex justify-content-between align-items-center flex-wrap" style=" gap: 16px;">

<!--      <div class="order-detail-btns " v-if="showBtns">
        <wameed-btn
            v-if="('can_update_price' in detail) && detail.can_update_price"
            variant="main"
            size="sm"
            :title="addPriceBtnText"
            classes="text-book-18  rounded-12   px-4 main-btn"
            block
            @onClick="$emit('onAddPrices')"
        />
        <wameed-btn
            variant="gray"
            size="sm"
            :title="$t('orders.edit')"
            classes="text-book-18  rounded-12   px-4 secondary-btn"
            block
            @onClick="$emit('onEdit')"
        />
        <wameed-btn
            variant="success"
            size="sm"
            :title="$t('orders.accept')"
            classes="text-book-18  rounded-12   px-4 success-btn"
            block

            @onClick="$emit('onAccept')"
        />
        <wameed-btn
            variant="danger"
            size="sm"
            :title="$t('orders.cancel')"
            classes="text-book-18  rounded-12   px-4"
            block

            @onClick="$emit('onCancel')"
        />
      </div>-->

<!--      <wameed-btn
          variant="outline-main"
          size="sm"
          prependIcon="btn-arrow-left-icon"
          :title="$t('status.change')"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onChangeStatus')"
      />-->
    </div>
  </div>
</template>
<script>

import { now } from 'moment';
import CheckPrice from '@/components/checkPrice.vue';

export default {
  name: 'infoSection',
  components: { CheckPrice },
  methods: { now },
  props: {
    detail: {},
    edit: {
      type: Boolean,
      default: false
    },
    addPriceBtnText: {
      type: String,
      default: ""
    },
  },
  computed: {
    showBtns() {
      return this.detail.status_type != null && this.detail.status_type == 0 && this.edit == false;
    },
    dayNameArabic() {
      return new Intl.DateTimeFormat('ar-EG', { weekday: 'long' }).format(new Date());
    },
    // Format date as yyyy-mm-dd
    formattedDate() {
      const year = (new Date()).getFullYear();
      const month = String((new Date()).getMonth() + 1).padStart(2, '0');
      const day = String((new Date()).getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }

}
</script>