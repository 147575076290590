import router from '@/router';

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`delivery_day_report${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    console.log(respo.data.data)
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getCaptains({commit}) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData([]);

        await this._vm.$http
          .get(`fin_income_create/4${filters}`)
          .then(respo => {
              if (respo.status === 200) {
                  console.log(respo.data.data)
                  commit('setCaptains', respo.data.data);
                  commit('setWallets', respo.data.data);
                  commit('setCurrencies', respo.data.data);
                  commit('setPenalties', respo.data.data);
                  commit('setBonuses', respo.data.data);

                  commit('loadingFinish', null, {root: true});
              }
          })
          .catch(error => {
              commit('loadingFinish', null, {root: true});
              throw error;
          },);
    },

  async getVendors({commit}) {
    commit('loadingStart', null, {root: true});

    const filters = window.mapFilterData([]);

    await this._vm.$http
      .get(`fin_income_create/3${filters}`)
      .then(respo => {
        if (respo.status === 200) {
          console.log("Vendors")
          console.log(respo.data.data)
          commit('setVendors', respo.data.data);
          commit('setWallets', respo.data.data);
          commit('setCurrencies', respo.data.data);

          commit('loadingFinish', null, {root: true});
        }
      })
      .catch(error => {
        commit('loadingFinish', null, {root: true});
        throw error;
      },);
  },

  async getAccounts({commit}){
    await this._vm.$http
      .get(`fin_create_account`)
      .then(respo => {
        if (respo.status === 200) {
          console.log(respo.data.data)
          commit('setCaptains', respo.data.data);
          commit('setAccounts', respo.data.data);

          commit('loadingFinish', null, {root: true});
        }
      })
      .catch(error => {
        commit('loadingFinish', null, {root: true});
        throw error;
      },);
  },

  async loadTransactions({commit}){
    commit('loadingStart', null, {root: true});
    await this._vm.$http
      .get(`load_penalties_bonuses`)
      .then(respo => {
        if (respo.status === 200) {
          console.log(respo.data.data)
          commit('setTransactions', respo.data);

          commit('loadingFinish', null, {root: true});
        }
      })
      .catch(error => {
        commit('loadingFinish', null, {root: true});
        throw error;
      },);
  },

  async storeIncome({commit}, { type, data }) {
    commit('loadingStart', null, {root: true});
    console.log(type)
    console.log(data)

    await this._vm.$http
      .post(`fin_store/${type}`, data)
      .then(response => {
        if (response.status === 200) {
          console.log(response);
          commit('successMessage', 'save', {root: true});
          commit('loadingFinish', null, {root: true});
          if (router.currentRoute.name === 'create-penalties') {
            router.back();
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
};
