

<template>
  <div ref="printSection" class="voucher border p-4 rounded shadow bg-white mx-auto">
    <!-- شعار الشركة -->
    <div class="text-center my-5">
      <img :src="companyLogo" alt="Company Logo" class="img-fluid" style="min-width: 160px;" />
    </div>

    <!-- عنوان السند -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="m-0">سند قبض</h2>
      <span class="text-muted">(رقم: {{ voucherNumber }})</span>
    </div>

    <!-- التفاصيل -->
    <div class="voucher-details">
      <p><strong>التاريخ:</strong> {{ printDate }}</p>
      <p><strong>استلمت من الأخ:</strong> {{ recipientName }}</p>
      <p><strong>مبلغ وقدره:</strong> {{ amount }}</p>
      <p><strong>العملة:</strong> {{ currency }}</p>
      <p><strong>وذلك مقابل:</strong> <br/> {{ reason }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyLogo: "/logo-dark.svg", // شعار الشركة
      printDate: new Date().toISOString().slice(0, 10), // تاريخ الطباعة
      recipientName: "محمد أحمد", // المستلم
      amount: "5000 ريال", // المبلغ
      currency: "ريال سعودي", // العملة
      reason: "سداد دفعة مستحقة", // السبب
      voucherNumber: 4312421
    };
  },
  methods: {
    printVoucher() {
      window.print();
    },
  },
};
</script>

<style scoped>
.voucher {
  //direction: rtl; /* لدعم النصوص العربية */
  //text-align: right;
  max-width: 600px; /* تحديد العرض الأقصى للسند */
  margin: auto; /* لضمان توسيطه أفقياً */
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.text-muted {
  font-size: 18px;
  color: #888;
}

.voucher-details p {
  font-size: 18px;
  margin-bottom: 8px;
}
</style>
