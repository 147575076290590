import i18n from "@/libs/i18n";
import router from "@/router";

export default {
    async get({commit}, data) {
        commit('loadingStart', null, {root: true});

        const filters = window.mapFilterData(data);

        await this._vm.$http
            .get(`users/${data.type}${filters}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setData', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetData({commit}) {
        commit('unsetData');
    },

    async exportExcel({commit}, data) {
        commit('loadingStart', null, {root: true});


        await this._vm.$http
            .get(`users/${data.type}/export-excel`, {responseType: 'blob'})
            .then(respo => {
                if (respo.status === 200) {
                    // commit('setData', respo.data.data);
                    const url = window.URL.createObjectURL(new Blob([respo.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', data.type + '.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async importExcel({commit, dispatch}, data) {
        commit('loadingStart', null, {root: true});


        return await this._vm.$http
            .post(`users/${data.type}/import-excel`, data)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});
                    return true;
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async simpleDetail({commit}, data) {
        commit('loadingStart', null, {root: true});

        await this._vm.$http
            .get(`users/${data.type}/simple/${data.id}`)
            .then(respo => {
                if (respo.status === 200) {
                    console.log(respo.data.data);
                    commit('setDetail', respo.data.data);

                    commit('loadingFinish', null, {root: true});

                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async unsetDetail({commit}) {
        commit('unsetDetail');
    },

    async toggle({commit}, data) {
        commit('loadingStart', null, {root: true});


        return await this._vm.$http
            .post(`users/toggle`, data)
            .then(respo => {
                if (respo.status === 200) {
                    commit('updateData', respo.data.data);

                    commit('successMessage', 'toggle', {root: true});
                    commit('loadingFinish', null, {root: true});
                    return respo.data.data
                }
            })
            .catch(error => {
                commit('failMessage', 'toggle', {root: true});
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async create({commit}, data) {
        commit('loadingStart', null, {root: true});
        // console.log(data);
        await this._vm.$http
            .post(`users/${data.type}/create`, data)
            .then(respo => {
                if (respo.status === 200) {
                    if (data.type !== 'vendors') {
                        router.push({name: `users-${data.type}`, params: {lang: i18n.locale}});
                    }
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

    async update({commit}, data) {
        commit('loadingStart', null, {root: true});
        await this._vm.$http
            .post(`users/${data.type}/update/${data.id}`, data)
            .then(respo => {
                if (respo.status === 200) {
                    router.push({name: `users-${data.type}`, params: {lang: i18n.locale}});
                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },


    async getVehicleTypes({commit}) {

        await this._vm.$http
            .get(`users/drivers/vehicle-types`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setVehicleTypes', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async getVendorCategories({commit}) {

        await this._vm.$http
            .get(`users/vendors/categories`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setVendorCategories', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },
    async getVendorClassification({commit}, id) {

        await this._vm.$http
            .get(`users/vendors/classifications/${id}`)
            .then(respo => {
                if (respo.status === 200) {
                    commit('setVendorClassification', respo.data.data);

                    commit('loadingFinish', null, {root: true});
                }
            })
            .catch(error => {
                commit('loadingFinish', null, {root: true});
                throw error;
            },);
    },

    async resetVendorPassword({commit}, id) {
        commit('loadingStart', null, {root: true});
        return await this._vm.$http
            .post(`users/vendors/reset-password/${id}`)
            .then(respo => {
                if (respo.status === 200) {

                    commit('loadingFinish', null, {root: true});
                }
                return respo.data.data;
            })
            .catch(error => {

                commit('loadingFinish', null, {root: true});
                throw error;
            });
    },

};
