export default {
  data: [],
  captains:[],
  vendors: [],
  accounts: [],
  transactions: [],
  penalties: [],
  bonuses: [],
  wallets: [],
  currencies: [],
  total: {
    totalItems: 5,
    totalPages: 5,
  },

  applyFor: [],
};
