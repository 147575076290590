export default {
    setData: (state, data) => {
        state.data = data;
    },

    unsetVendors: (state) => {
        if (state.data) {
            state.data.vendors = {
                "vendors": [],
                "total": 0
            }
        }
    }


};
