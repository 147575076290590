<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.financial.reports.special.drivers')"
        :paths="pagePath()"
    />
    <section class="wameed-dashboard-page-content_body products_create order-detail">

      <DemoInfoSection :detail="getAllFinancialReports" :date="filterData['startDate'] != null ? filterData['startDate'] : new Date()" />

      <div class="row gap-4 border rounded p-3 bg-light" style="margin: 10px;">
        <b-form-checkbox
          v-for="field in fields"
          :key="field.key"
          class="form-check-inline mx-3"
          v-model="visibleColumns"
          :value="field.key"
          :unchecked-value="null"
        >
          {{ field.label }}
        </b-form-checkbox>
      </div>

      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderItems"
      />

      <div class="order-detail-table">
        <b-card
            no-body
            class="wameed-card"
        >
          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('nav.financial.reports.special.daily_details') }}
            </h5>
          </b-card-header>

          <div class="w-table d-flex flex-column">

            <drivers-table :items="getAllFinancialReports" :fields="fields" :visibleColumns="visibleColumns" />
          </div>
        </b-card>
      </div>
    </section>

  </div>
</template>

<script>

// import {WameedTextInput} from 'wameed-ui/dist/wameed-ui.esm'
import PageHeader from '@/components/wameed/WameedPageHeader.vue';

// import BillPriceTile from "@/views/pages/orders/components/billPriceTile";
// import OrderTable from "@/views/pages/orders/components/orderTable";
// import PersonInfoCard from "@/views/pages/orders/components/personInfoCard";
// import CustomerRate from "@/components/customerRate";
import DemoInfoSection from "@/views/pages/financial-reports/drivers/DemoInfoSection";
// import ProductCard from "@/views/pages/orders/components/productCard";
import DriversTable from '@/views/pages/financial-reports/drivers/components/driversTable.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import { now } from 'moment';

export default {
  components: {
    FilterHeader,
    DriversTable,
    // ProductCard,
    // TextInput,
    DemoInfoSection,
    // CustomerRate,
    // PersonInfoCard,
    // OrderTable,
    // BillPriceTile,
    PageHeader,
  },
  props: {
    path: Array
  },
  data() {
    return {
      fields: [
        // { key: "number", label: "#", sortable: true },
        { key: "driver_name", label: "إسم الكابتن", sortable: true },
        { key: "total_orders", label: "عدد الطلبات", sortable: true },
        { key: "total_order_price", label: "اجمالي قيمة الطلبات", sortable: true },
        { key: "total_delivery_price", label: "اجمالي التوصيل", sortable: true },
        { key: "total_fee", label: "اجمالي رسوم الخدمة", sortable: true },
        { key: "total_invoice", label: "الإجمالي للفاتورة", sortable: true },
        // { key: "total_cash_order_price", label: "اجمالي الطلبات النقدية بعد خصم نسبة الشركة", sortable: true },
        { key: "total_cash_order_price", label: "اجمالي قيمة نسبة الشركة من الطلبات النقدية", sortable: true },
        { key: "total_postpaid_order_price", label: "اجمالي قيمة الطلبات الآجلة", sortable: true },
        { key: "total_discount_price", label: "إجمالي الخصومات من قيمة الطلبات (من المورد وطلقة)", sortable: true },
        { key: "total_delivery_discount_price", label: "إجمالي الخصومات من قيمة التوصيل", sortable: true },
        { key: "total_customer_paid_price", label: "مبلغ التوصيل المدفوع من العميل", sortable: true },
        { key: "total_delivery_discount_provider", label: "قيمة تحمل المندوب من التوصيل", sortable: true },
        { key: "delivery_price_after_discount", label: "سعر التوصيل بعد خصم % تحمل المندوب", sortable: true },
        { key: "company_compensation", label: "مبلغ تعويض الشركة للمندوب", sortable: true },
        // { key: "company_percentage", label: "% الشركة من المندوب بحسب العقد", sortable: true },
        { key: "company_percentage_value", label: "قيمة % الشركة من المندوب بحسب العقد", sortable: true },
        // { key: "holidays_allowance", label: "مقابل دوام الاجازات", sortable: true },
        { key: "bonuses", label: "له", sortable: true },
        // { key: "exceptional_compensation", label: "التعويض الاستثنائي", sortable: true },
        { key: "penalties", label: "عليه", sortable: true },
        { key: "net_amount_due", label: "صافي المبلغ المستحق للمندوب", sortable: true },
        { key: "net_amount_for_company_before_compensations", label: "الصافي للشركة قبل تعويضات المندوبين", sortable: true },
        { key: "net_amount_for_company_after_compensations", label: "الصافي النهائي للشركة من التوصيل بعد تعويضات المندوبين", sortable: true },
        { key: "net_amounts_to_be_supplied", label: "صافي المبالغ اللازمة التوريد من المندوب", sortable: true },
        { key: "amounts_received", label: "المقبوض", sortable: true },
        // { key: "insurance_amount", label: "سقف التأمين", sortable: true },
        { key: "balance", label: "الرصيد", sortable: true },
        // { key: "cash", label: "نقد", sortable: true },
        // { key: "advance", label: "سلفة", sortable: true },
        // { key: "wallet", label: "محفظة", sortable: true },
      ],
      // Initialize visibleColumns with all keys from fields
      visibleColumns: [],
      filterData: {

        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      driverContactModal: {
        icon: "o-show-icon",
        title: this.$i18n.t('orders.driverModal.show.title'),
        subTitle: this.$i18n.t('orders.driverModal.show.sub_title'),
        btn: this.$i18n.t('orders.driverModal.show.btn'),
      },
      productModalData: null,
      talqahOrderData: {},
      productSearch: '',
      changeOrderStatusModal: false,
      showProductModal: false,
      isMedicine: false,
      editOrderType: false,
      showEditTalqahModal: false,
      showConfirmEditModal: false,
      editCart: false,
      showDriverToCustomer: false,
      showCancelModal: false,
      showDriverContactModal: false,
      showDriverModal: false,
      showPricesModal: false,
      showEditAddressModal: false,
      editAddressModalType: '',
      content: [
        {
          title: "التأريخ", /*TODO: Translate*/
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          date: null,
          end_date: null,
        },
      ],
    }
  },

  methods: {
    now,
    ...mapActions({
      loadDayReport: 'admin/financialReports/get'
    }),
    pagePath() {
      if (this.path) {
        return this.path
      }
      return [{title: this.$i18n.t('nav.financial.reports.special.drivers'), link: 'drivers-reports'}];
    },

    loadData() {
      this.loadDayReport({
        // type: this.$route.meta.type,
        ...this.filterData
      });
      console.log(this.data);
    },

    applyFilter() {
      this.content.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },

    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderItems(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

    fillTableFilelds() {
      // let item = {
      //   key: 'email',
      //   label: this.$i18n.t('table.email'),
      //   sortable: true,
      // };
      // if (this.$route.meta.type == 'vendors') {
      //   this.fields.splice(4, 0, item,);
      //
      // } else {
      //   this.fields = this.fields.filter((item) => {
      //     return item.key !== 'email';
      //   })
      // }
    },
  },

  computed: {
    ...mapGetters({
      getAllFinancialReports: 'admin/financialReports/getData',
      // getTotalFinancial: 'admin/financial/getTotal',
    }),
  },

  created() {
    this.visibleColumns = this.fields.map(field => field.key);
    this.fillTableFilelds();
    this.loadData();
    console.log(this.getAllFinancialReports);
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.fillTableFilelds();
        this.loadData();
      }
    },
  },

  beforeCreate() {

  },

};
</script>
