<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
      :key="key"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            ملاحظات
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <text-area
              id="package-ad"
              v-model="form.note"
              label="ملاحظات الطباعة"
              :placeholder="$t('form.text.placeholder')"
          />
        </b-col>

      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.send')"
            :disabled="invalid"
            type="submit"
            variant="main"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextArea as TextArea} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextArea,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    order: {
      type: [Number, String],
      default: null,
    },

  },

  data() {
    return {
      key: 0,
      form: {
        note: '',
        reason: null,
      },
      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    submitPrint() {
      if (this.category == null) {
        this.closeModal();
      }
    },
    closeModal() {
      this.show = false;
      this.form.note = '';
    },
  },
};
</script>
