//import i18n from "@/libs/i18n";




const PaymentMethod = [
    {
        id: 1,
        value: "cash",
        name: "نقد"// i18n.t('users.client.individual'),
    },
    {
        id: 2,
        value: "wallet",
        name: "محفظة"// i18n.t('users.client.company'),
    },


];

function filterPaymentMethod(id) {
    return PaymentMethod.find((item) => item.id === id);
}

export {PaymentMethod, filterPaymentMethod};
