<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('nav.financial.reports.special.vendors')"
        :paths="pagePath()"
    />
    <section class="wameed-dashboard-page-content_body products_create order-detail">

      <DemoInfoSection />

<!--      <div class="card-info-section mb-5">

        <person-info-card
            v-if="detail.service_is_booking==0"
            :class="'card-info'"
            :link="goToDriverDetail"
            :title="$t('orders.info.driver')"
            :info="detail.driver"
            :btn="driverBtnTitle"
            :enable-btn="detail.can_assign_driver"
            :btn-color="detail.driver.id==0?'btn-info':'btn-main'"
            @onClick="driverBtnOnClick">
          <wameed-btn
              v-if="detail.driver.id!=0 && detail.can_assign_driver"
              variant="gray"
              size="sm"
              :title="$t('orders.reassign_driver')"
              :classes="`text-book-18  rounded-12 w-unset px-4 btn-info`"
              block
              @onClick="showDriverModal = true"
          />
        </person-info-card>

        <person-info-card
            :link="goToVendorDetail"
            :class="'card-info'"
            :title="$t('orders.info.vendor')"
            :info="detail.vendor"
            :btn="$t('common.show_details')"
            @onClick="showVendorDetail(detail.vendor.id)"
        />

        <person-info-card
            :link="goToClientDetail"
            v-if="detail.from_address"
            :enable-btn="detail.can_update_addresses"
            :class="'card-info'"
            :show-btns="detail.service_is_booking==0"
            :title="$t('orders.info.from')"
            :info="detail.from_address"
            :btn="$t('orders.edit_address')"
            @onClick="showEditAddress('from')"/>


        <person-info-card
            :link="goToClientDetail"
            :show-btns="detail.service_is_booking==0"
            :enable-btn="detail.can_update_addresses"
            :class="'card-info'"
            :title="detail.from_address==null ?$t('orders.info.customer') :$t('orders.info.to')"
            :info="detail.to_address"
            :btn="$t('orders.edit_address')"
            @onClick="showEditAddress('to')"/>


      </div>-->


<!--
      <b-card
          no-body
          class="wameed-card mb-5"
          v-if="vendorProducts.length>0"
      >
        <div class="vendor-products-section">

          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('orders.products.title') }}
            </h5>
            <div class="search">
              <text-input
                  v-model="productSearch"
                  icon="search-icon"
                  is-append
                  input-classes="text-reg-14 "
                  :placeholder="$t('common.search') + ' ...'"
                  field-classes="mb-0 search-form-control"
              />
            </div>
          </b-card-header>

          <div class="order-vendor-categories p-5">

            <div v-for="cat in vendorProducts" :key="cat.id">
              <template v-if="cat.products && cat.products.length>0">
                <div class="category-name">{{ cat.name }}</div>
                <div class="order-vendor-products">

                  <div
                      v-for="product in cat.products.filter((prod)=>prod.name.indexOf(productSearch) > -1 ||productSearch=='')"
                      :key="product.id">
                    <product-card :data="product" @onClick="onProductClick(product.id)"/>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-card>
-->

<!--      <b-card
          no-body
          class="wameed-card mb-5"
          v-if="detail.review"
      >
        <div class="">

          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('table.rating') }}
            </h5>
          </b-card-header>

          <div class="order-detail-info p-5">


            <div class="flex">
              <div class="tile-text">
                <div class="text">{{ $t('table.talqah_rating') }}</div>
                <div class="value">
                  <customer-rate :rate="detail.review.talqah_rating"/>
                </div>
              </div>
              <div class="tile-text">
                <div class="text">{{ $t('table.rated_at') }}</div>
                {{ formatDate(detail.review.date) }}
              </div>
            </div>

            <div class="flex">
              <div class="tile-text">
                <div class="text">{{ $t('table.vendor_rating') }}</div>
                <div class="value">
                  <customer-rate :rate="detail.review.vendor_rating"/>
                </div>
              </div>
              <div class="tile-text">
                <div class="text">{{ $t('table.driver_rating') }}</div>
                <div class="value">
                  <customer-rate :rate="detail.review.driver_rating"/>
                </div>
              </div>

            </div>

            <div class="divider"/>
            <div>
              <div class="tile-text col">
                <div class="text">{{ $t('table.comment') }}</div>
                <div class="value">{{ detail.review.comment }}</div>
              </div>


            </div>
          </div>
        </div>
      </b-card>-->

      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />

      <div class="order-detail-table">
        <b-card
            no-body
            class="wameed-card"
        >
          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('nav.financial.reports.special.daily_details') }}
            </h5>
          </b-card-header>
          <div>
          <div class="w-table d-flex flex-column">
            </div>
            <vendors-table />
          </div>
        </b-card>
      </div>
    </section>

  </div>
</template>

<script>

import {WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'
import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import BillPriceTile from "@/views/pages/orders/components/billPriceTile";
import OrderTable from "@/views/pages/orders/components/orderTable";
import PersonInfoCard from "@/views/pages/orders/components/personInfoCard";
import CustomerRate from "@/components/customerRate";
import DemoInfoSection from "@/views/pages/financial-reports/vendors/DemoInfoSection";
import ProductCard from "@/views/pages/orders/components/productCard";
import VendorsTable from '@/views/pages/financial-reports/vendors/components/vendorsTable.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';

export default {
  components: {
    FilterHeader,
    VendorsTable,
    ProductCard,
    TextInput,
    DemoInfoSection,
    CustomerRate,
    PersonInfoCard,
    OrderTable,
    BillPriceTile,
    PageHeader,
  },
  props: {
    path: Array
  },
  data() {
    return {
      driverContactModal: {
        icon: "o-show-icon",
        title: this.$i18n.t('orders.driverModal.show.title'),
        subTitle: this.$i18n.t('orders.driverModal.show.sub_title'),
        btn: this.$i18n.t('orders.driverModal.show.btn'),
      },
      content: [
        {
          title: this.$i18n.t('orders.table.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          day: null,
        },
      ],
      productModalData: null,
      talqahOrderData: {},
      productSearch: '',
      changeOrderStatusModal: false,
      showProductModal: false,
      isMedicine: false,
      editOrderType: false,
      showEditTalqahModal: false,
      showConfirmEditModal: false,
      editCart: false,
      showDriverToCustomer: false,
      showCancelModal: false,
      showDriverContactModal: false,
      showDriverModal: false,
      showPricesModal: false,
      showEditAddressModal: false,
      editAddressModalType: '',
    }
  },

  methods: {
    pagePath() {
      if (this.path) {
        return this.path
      }
      return [{title: this.$i18n.t('nav.financial.reports.special.vendors'), link: 'vendors-reports'}];
    },

    applyFilter() {
      this.content.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },

  watch: {
    $route(to, from) {
      if (from !== to) {
        this.loadData();
      }
    },
  },

  beforeCreate() {

  },

};
</script>
